import React, {useState, useEffect, useRef}from "react";
import './services-details.css';
import EjeBusiness from '../images/EjeBusiness.png';
import EjeDigital from '../images/EjeDigital.png';
import EjeAnalytics from '../images/EjeAnalytics.png';


const ServicesDetails = () => {
  const [isActive1, setIsActive1] = useState(true);
  const [isActive12, setIsActive12] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);



  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);
  const contentRef5 = useRef(null);
  const contentRef6 = useRef(null);

  const handleClick1 = () => {
    setIsActive1(true);
    setIsActive12(true);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
    setIsActive5(false);
    setIsActive6(false);
  };

  const handleClick2 = () => {
    setIsActive1(false);
    setIsActive12(false);
    setIsActive2(true);
    setIsActive3(false);
    setIsActive4(false);
    setIsActive5(false);
    setIsActive6(false);
  };

  const handleClick3 = () => {
    setIsActive1(false);
    setIsActive12(false);
    setIsActive2(false);
    setIsActive3(true);
    setIsActive4(false);
    setIsActive5(false);
    setIsActive6(false);
  };

  const handleClick4 = () => {
    setIsActive1(false);
    setIsActive12(false);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(true);
    setIsActive5(false);
    setIsActive6(false);
  };

  const handleClick5 = () => {
    setIsActive1(false);
    setIsActive12(false);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
    setIsActive5(true);
    setIsActive6(false);
  };

  const handleClick6 = () => {
    setIsActive1(false);
    setIsActive12(false);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
    setIsActive5(false);
    setIsActive6(true);
  };
  

  useEffect(() => {
    const content = contentRef1.current;

    if (isActive12) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [isActive12]);

  useEffect(() => {
    const content = contentRef2.current;

    if (isActive2) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [isActive2]);

  useEffect(() => {
    const content = contentRef3.current;

    if (isActive3) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [isActive3]);

  useEffect(() => {
    const content = contentRef4.current;

    if (isActive4) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [isActive4]);

  useEffect(() => {
    const content = contentRef5.current;

    if (isActive5) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [isActive5]);

  useEffect(() => {
    const content = contentRef6.current;

    if (isActive6) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [isActive6]);

  return (
    <>
      <div className="c-section hm-section-1 services-details">
        <div className="o-container">
          <div className="c-line"></div>

          <div className="rectangle-container">
      <div className="rectangle">
        <div className="image-frame">
          <div className="image-container">
              <img src={EjeBusiness} alt="Placeholder" className="square-image" />
          </div>
        </div>
        <div className="content-container">
          <h2 className="title">BUSINESS</h2>
          <p className="text">Empowering your strategy with innovative business solutions. From market analysis to strategic consulting, we help you navigate the complexities of the business world.</p>
          <a href="/services/business">
          <button className="action-button">
          <span>
              <svg fill="#007bff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
              viewBox="0 0 512 512" xmlSpace="preserve" className="w-inline-block box-arrow">
                <g>
                  <g>
                    <path d="M0,0v512h512V0H0z M469.333,469.333c-8.341,8.341-21.824,8.341-30.165,0L85.333,115.499v225.835
                    c0,11.776-9.536,21.333-21.333,21.333s-21.333-9.557-21.333-21.333V64c0-2.773,0.576-5.547,1.643-8.149
                    c2.155-5.227,6.315-9.387,11.541-11.541c2.603-1.067,5.376-1.643,8.149-1.643h277.333c11.797,0,21.333,9.557,21.333,21.333
                    s-9.536,21.333-21.333,21.333H115.499l353.835,353.835C477.675,447.509,477.675,460.992,469.333,469.333z"/>
                  </g>
                </g>
              </svg>
            </span>
            Learn More</button></a>
        </div>
      </div>
    </div>

    <div className="rectangle-container digital">
      <div className="rectangle">
        <div className="content-container">
          <h2 className="title">DIGITAL</h2>
          <p className="text">Transform your vision into digital reality. Our team creates cutting-edge websites, apps, and digital experiences that drive engagement and growth.</p>
          <a href="/services/digital">
          <button className="action-button">
          <span>
              <svg fill="#007bff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  xmlnsXlink="http://www.w3.org/1999/xlink" 
              viewBox="0 0 512 512" xmlSpace="preserve" className="w-inline-block box-arrow">
                <g>
                  <g>
                    <path d="M0,0v512h512V0H0z M469.333,469.333c-8.341,8.341-21.824,8.341-30.165,0L85.333,115.499v225.835
                    c0,11.776-9.536,21.333-21.333,21.333s-21.333-9.557-21.333-21.333V64c0-2.773,0.576-5.547,1.643-8.149
                    c2.155-5.227,6.315-9.387,11.541-11.541c2.603-1.067,5.376-1.643,8.149-1.643h277.333c11.797,0,21.333,9.557,21.333,21.333
                    s-9.536,21.333-21.333,21.333H115.499l353.835,353.835C477.675,447.509,477.675,460.992,469.333,469.333z"/>
                  </g>
                </g>
              </svg>
            </span>
            Learn More</button></a>
        </div>
        <div className="image-frame">
          <div className="image-container">
              <img src={EjeDigital} alt="Placeholder" className="square-image" />
          </div>
        </div>
      </div>
    </div>

    <div className="rectangle-container">
      <div className="rectangle">
        <div className="image-frame">
          <div className="image-container">
              <img src={EjeAnalytics} alt="Placeholder" className="square-image" />
          </div>
        </div>
        <div className="content-container">
          <h2 className="title">ANALYTICS / AI</h2>
          <p className="text">Unlock the power of data with advanced analytics and AI. We turn data into actionable insights, driving smarter decisions and competitive advantage.</p>
          <a href="/services/analytics">
          <button className="action-button">
            <span>
              <svg fill="#007bff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
              viewBox="0 0 512 512" xmlSpace="preserve" className="w-inline-block box-arrow">
                <g>
                  <g>
                    <path d="M0,0v512h512V0H0z M469.333,469.333c-8.341,8.341-21.824,8.341-30.165,0L85.333,115.499v225.835
                    c0,11.776-9.536,21.333-21.333,21.333s-21.333-9.557-21.333-21.333V64c0-2.773,0.576-5.547,1.643-8.149
                    c2.155-5.227,6.315-9.387,11.541-11.541c2.603-1.067,5.376-1.643,8.149-1.643h277.333c11.797,0,21.333,9.557,21.333,21.333
                    s-9.536,21.333-21.333,21.333H115.499l353.835,353.835C477.675,447.509,477.675,460.992,469.333,469.333z"/>
                  </g>
                </g>
              </svg>
            </span>
            Learn More</button></a>
        </div>
      </div>
    </div>
          
        </div>
      </div>
      <div className="c-section services-section-2">
        <div className="o-container">
          <div className="o-row title margin-64">
            <div className="o-col _w-16 sm-w-24">
              <h2 className="t-display-2 is-serif">Our comprehensive work strategy</h2>
            </div>
            <div className="o-col _w-8 md-w-14 sm-w-24 hide-tablet">
              <p title-p-optical className="t-body-4">
              At Emines Junior Entreprise, our approach to every project is meticulously crafted to ensure success from start to finish. Our work strategy is divided into three key phases: Preparation, Development, and Post-Delivery. Each phase is designed to guarantee that we meet and exceed client expectations, delivering exceptional results that drive value and innovation.
              </p>
            </div>
          </div>
          <div className="o-row values">
            <div draw-line className="c-line values-line"></div>
            <div relative className="o-col _w-12 md-w-24">
              <div className="swiper value w-dyn-list hide-tablet">
                <div role="list" className="swiper-wrapper value w-dyn-items">
                  <div role="listitem" className={`swiper-slide value w-dyn-item ${isActive1? 'is-active' : ''}`} onClick={handleClick1}>
                    <div className="c-line value_top"></div>
                    <div className="swiper-text-title">
                      <div className='services-text-small'>01 /</div>
                      <div className="t-body-2 is-serif">Discovery & Analysis</div>
                    </div>
                    <div className="c-value-arrow">
                      <div className="c-icon value-arrow w-embed">
                      <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                      </svg>
                      </div>
                    </div>
                    <div className="c-line value_bt"></div>
                  </div>
                  <div role="listitem" className={`swiper-slide value w-dyn-item ${isActive2? 'is-active' : ''}`} onClick={handleClick2}>
                    <div className="c-line value_top"></div>
                    <div className="swiper-text-title">
                      <div className='services-text-small'>02 /</div>
                      <div className="t-body-2 is-serif">Strategic Planning</div>
                    </div>
                    <div className="c-value-arrow">
                      <div className="c-icon value-arrow w-embed">
                      <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                      </svg>
                      </div>
                    </div>
                    <div className="c-line value_bt"></div>
                  </div>
                  <div role="listitem" className={`swiper-slide value w-dyn-item ${isActive3? 'is-active' : ''}`} onClick={handleClick3}>
                    <div className="c-line value_top"></div>
                    <div className="swiper-text-title">
                      <div className='services-text-small'>03 /</div>
                      <div className="t-body-2 is-serif">Design & Prototyping</div>
                    </div>
                    <div className="c-value-arrow">
                      <div className="c-icon value-arrow w-embed">
                      <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                      </svg>
                      </div>
                    </div>
                    <div className="c-line value_bt"></div>
                  </div>
                  <div role="listitem" className={`swiper-slide value w-dyn-item ${isActive4? 'is-active' : ''}`} onClick={handleClick4}>
                    <div className="c-line value_top"></div>
                    <div className="swiper-text-title">
                      <div className='services-text-small'>04 /</div>
                      <div className="t-body-2 is-serif">Implementation & Development</div>
                    </div>
                    <div className="c-value-arrow">
                      <div className="c-icon value-arrow w-embed">
                      <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                      </svg>
                      </div>
                    </div>
                    <div className="c-line value_bt"></div>
                  </div>
                  <div role="listitem" className={`swiper-slide value w-dyn-item ${isActive5? 'is-active' : ''}`} onClick={handleClick5}>
                    <div className="c-line value_top"></div>
                    <div className="swiper-text-title">
                      <div className='services-text-small'>05 /</div>
                      <div className="t-body-2 is-serif">Testing & Quality Assurance</div>
                    </div>
                    <div className="c-value-arrow">
                      <div className="c-icon value-arrow w-embed">
                      <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                      </svg>
                      </div>
                    </div>
                    <div className="c-line value_bt"></div>
                  </div>
                  <div role="listitem" className={`swiper-slide value w-dyn-item ${isActive6? 'is-active' : ''}`} onClick={handleClick6}>
                    <div className="c-line value_top"></div>
                    <div className="swiper-text-title">
                      <div className='services-text-small'>06 /</div>
                      <div className="t-body-2 is-serif">Delivery & Support</div>
                    </div>
                    <div className="c-value-arrow">
                      <div className="c-icon value-arrow w-embed">
                      <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                      </svg>
                      </div>
                    </div>
                    <div className="c-line value_bt"></div>
                  </div>
                </div>
              </div>
              <div className="c-panel-wrap w-dyn-list show-tablet">
                <div role="list" className="w-dyn-items">
                  <div role="listitem" className={`c-panel w-dyn-item ${isActive12? 'is-active' : ''}`} onClick={handleClick1}>
                    <div className="c-panel_top">
                      <div className="t-body-1">
                      <div className="swiper-text-title">
                      <div className='services-text-small'>01 /</div>
                      <div className="t-body-2 is-serif">Discovery & Analysis</div>
                    </div>
                      </div>
                      <div className="c-value-arrow">
                        <div className="c-icon value-arrow w-embed">
                        <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c-panel_bt" ref={contentRef1}>
                      <div className="c-value-content_top">
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Before we embark on any project, we begin with a thorough discovery and analysis phase. During this stage, we work closely with our clients to understand their needs, goals, and challenges. We conduct market research, gather requirements, and analyze potential risks. This groundwork allows us to create a clear project roadmap and set realistic expectations.
                          </p>
                        </div>
                      </div>
                      <div className="c-value-content_bt">
                        <div className="c-value-content_bot-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div role="listitem" className={`c-panel w-dyn-item ${isActive2? 'is-active' : ''}`} onClick={handleClick2}>
                    <div className="c-panel_top">
                      <div className="t-body-1">
                      <div className="swiper-text-title">
                      <div className='services-text-small'>02 /</div>
                      <div className="t-body-2 is-serif">Strategic Planning</div>
                    </div>
                      </div>
                      <div className="c-value-arrow">
                        <div className="c-icon value-arrow w-embed">
                        <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c-panel_bt" ref={contentRef2}>
                      <div className="c-value-content_top">
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          With the insights gained from our discovery phase, we move on to strategic planning. This involves defining the project scope, setting objectives, and outlining deliverables. We also allocate resources, set timelines, and develop a detailed project plan. Our strategy is designed to align with our client's vision while ensuring feasibility and efficiency.
                          </p>
                        </div>
                      </div>
                      <div className="c-value-content_bt">
                        <div className="c-value-content_bot-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div role="listitem" className={`c-panel w-dyn-item ${isActive3? 'is-active' : ''}`} onClick={handleClick3}>
                    <div className="c-panel_top">
                      <div className="t-body-1">
                      <div className="swiper-text-title">
                      <div className='services-text-small'>03 /</div>
                      <div className="t-body-2 is-serif">Design & Prototyping</div>
                    </div>
                      </div>
                      <div className="c-value-arrow">
                        <div className="c-icon value-arrow w-embed">
                        <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c-panel_bt" ref={contentRef3}>
                      <div className="c-value-content_top">
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          In this phase, we translate our plans into actionable designs. Our team creates prototypes, wireframes, and design mockups that align with the project’s goals. We focus on user experience and visual appeal, ensuring that the designs meet both functional and aesthetic requirements. Client feedback is crucial at this stage, and we make iterative improvements based on input.
                          </p>
                        </div>
                      </div>
                      <div className="c-value-content_bt">
                        <div className="c-value-content_bot-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div role="listitem" className={`c-panel w-dyn-item ${isActive4? 'is-active' : ''}`} onClick={handleClick4}>
                    <div className="c-panel_top">
                      <div className="t-body-1">
                      <div className="swiper-text-title">
                      <div className='services-text-small'>04 /</div>
                      <div className="t-body-2 is-serif">Implementation & Development</div>
                    </div>
                      </div>
                      <div className="c-value-arrow">
                        <div className="c-icon value-arrow w-embed">
                        <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c-panel_bt" ref={contentRef4}>
                      <div className="c-value-content_top">
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Once the designs are approved, we move into the implementation phase. Our team of experts develops the product, whether it’s a digital platform, a business strategy, or an AI model. We use agile methodologies to ensure flexibility and adaptability throughout the development process. Regular updates and reviews are conducted to keep the project on track and aligned with client expectations.
                          </p>
                        </div>
                      </div>
                      <div className="c-value-content_bt">
                        <div className="c-value-content_bot-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div role="listitem" className={`c-panel w-dyn-item ${isActive5? 'is-active' : ''}`} onClick={handleClick5}>
                    <div className="c-panel_top">
                      <div className="t-body-1">
                      <div className="swiper-text-title">
                      <div className='services-text-small'>05 /</div>
                      <div className="t-body-2 is-serif">Testing & Quality Assurance</div>
                    </div>
                      </div>
                      <div className="c-value-arrow">
                        <div className="c-icon value-arrow w-embed">
                        <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c-panel_bt" ref={contentRef5}>
                      <div className="c-value-content_top">
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Before delivering the final product, we conduct rigorous testing to ensure it meets the highest standards of quality. Our quality assurance process includes functionality testing, user acceptance testing, and performance evaluations. We address any issues that arise, making sure the product is fully operational and meets all specifications.
                          </p>
                        </div>
                      </div>
                      <div className="c-value-content_bt">
                        <div className="c-value-content_bot-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div role="listitem" className={`c-panel w-dyn-item ${isActive6? 'is-active' : ''}`} onClick={handleClick6}>
                    <div className="c-panel_top">
                      <div className="t-body-1">
                      <div className="swiper-text-title">
                      <div className='services-text-small'>06 /</div>
                      <div className="t-body-2 is-serif">Delivery & Support</div>
                    </div>
                      </div>
                      <div className="c-value-arrow">
                        <div className="c-icon value-arrow w-embed">
                        <svg width="100%" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.0334 7.54809C13.8019 5.7342 11.3417 3.85778 8.56876 0.417706L7.27615 1.50181C9.23596 3.92028 10.8622 5.50481 12.8845 6.73491L0.833936 6.65128L0.833955 8.31917L12.9054 8.34026C10.8622 9.59113 9.25689 11.1547 7.27628 13.594L8.56892 14.6781C11.3418 11.2382 13.8436 9.36185 17.0334 7.54809Z" fill="currentColor"></path>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c-panel_bt" ref={contentRef6}>
                      <div className="c-value-content_top">
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Upon successful testing, we deliver the final product to our clients. But our work doesn’t end there. We provide post-delivery support, including training, documentation, and ongoing maintenance. We ensure that our clients can effectively use the product and that any future issues are quickly resolved. Our goal is to establish a long-term partnership that continues to provide value.
                          </p>
                        </div>
                      </div>
                      <div className="c-value-content_bt">
                        <div className="c-value-content_bot-inner"></div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="c-line vertical-divider values"></div>
            </div>
            <div className="o-col _w-12 md-w-24 hide-tablet">
            <div className="swiper value-content w-dyn-list">
                  <div role="list" className="swiper-wrapper value-content w-dyn-items">
                    <div role="listitem" className={`swiper-slide value-content w-dyn-item ${isActive1? 'is-active' : ''}`}>
                      <div className="c-value-content_top">
                        <div className="margin-32">
                          <h3 className="t-display-5">Discovery & Analysis</h3>
                        </div>
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Before we embark on any project, we begin with a thorough discovery and analysis phase. During this stage, we work closely with our clients to understand their needs, goals, and challenges. We conduct market research, gather requirements, and analyze potential risks. This groundwork allows us to create a clear project roadmap and set realistic expectations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div role="listitem" className={`swiper-slide value-content w-dyn-item ${isActive2? 'is-active' : ''}`}>
                      <div className="c-value-content_top">
                        <div className="margin-32">
                          <h3 className="t-display-5">Strategic Planning</h3>
                        </div>
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          With the insights gained from our discovery phase, we move on to strategic planning. This involves defining the project scope, setting objectives, and outlining deliverables. We also allocate resources, set timelines, and develop a detailed project plan. Our strategy is designed to align with our client's vision while ensuring feasibility and efficiency.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div role="listitem" className={`swiper-slide value-content w-dyn-item ${isActive3? 'is-active' : ''}`}>
                      <div className="c-value-content_top">
                        <div className="margin-32">
                          <h3 className="t-display-5">Design & Prototyping</h3>
                        </div>
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          In this phase, we translate our plans into actionable designs. Our team creates prototypes, wireframes, and design mockups that align with the project’s goals. We focus on user experience and visual appeal, ensuring that the designs meet both functional and aesthetic requirements. Client feedback is crucial at this stage, and we make iterative improvements based on input.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div role="listitem" className={`swiper-slide value-content w-dyn-item ${isActive4? 'is-active' : ''}`}>
                      <div className="c-value-content_top">
                        <div className="margin-32">
                          <h3 className="t-display-5">Implementation & Development</h3>
                        </div>
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Once the designs are approved, we move into the implementation phase. Our team of experts develops the product, whether it’s a digital platform, a business strategy, or an AI model. We use agile methodologies to ensure flexibility and adaptability throughout the development process. Regular updates and reviews are conducted to keep the project on track and aligned with client expectations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div role="listitem" className={`swiper-slide value-content w-dyn-item ${isActive5? 'is-active' : ''}`}>
                      <div className="c-value-content_top">
                        <div className="margin-32">
                          <h3 className="t-display-5">Testing & Quality Assurance</h3>
                        </div>
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Before delivering the final product, we conduct rigorous testing to ensure it meets the highest standards of quality. Our quality assurance process includes functionality testing, user acceptance testing, and performance evaluations. We address any issues that arise, making sure the product is fully operational and meets all specifications.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div role="listitem" className={`swiper-slide value-content w-dyn-item ${isActive6? 'is-active' : ''}`}>
                      <div className="c-value-content_top">
                        <div className="margin-32">
                          <h3 className="t-display-5">Delivery & Support</h3>
                        </div>
                        <div className="c-value-content-txt">
                          <p className="t-body-4">
                          Upon successful testing, we deliver the final product to our clients. But our work doesn’t end there. We provide post-delivery support, including training, documentation, and ongoing maintenance. We ensure that our clients can effectively use the product and that any future issues are quickly resolved. Our goal is to establish a long-term partnership that continues to provide value.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-section services-section-3">
        <div className="o-container">
          <div className="o-row vertical-center margin-64">
            <div className="o-col _w-20 md-w-24">
              <h2 className="t-display-2 is-serif text-center">Why EJE Stands Out ?</h2>
            </div>
          </div>
          <div className="o-row vertical-center margin-80">
            <div className="o-col _w-10 md-w-16 sm-w-24">
              <p className="t-body-4 text-center">
              At EJE, we set ourselves apart through a commitment to excellence, innovation, and personalized service. Our unique approach combines rigorous analysis with creative solutions, ensuring that each project not only meets but exceeds expectations.
              </p>
            </div>
          </div>
          <div className="o-row">
            <div className="c-build-wrap">
              <div className="c-build-item">
                <div className="margin-28">
                  <div className="c-icon medium w-embed">
                  <svg fill="#4376D9" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                      width="100%" viewBox="0 0 31.762 31.761"
                      xmlSpace="preserve">
                    <g>
                      <g>
                        <path d="M13.639,14.357c0.001,0.005,0.002,0.011,0.004,0.015h0.004C13.644,14.372,13.641,14.366,13.639,14.357z"/>
                        <path d="M13.639,14.357c-0.004-0.021-0.008-0.045-0.015-0.063C13.63,14.314,13.634,14.34,13.639,14.357z"/>
                        <polygon points="25.92,14.372 25.922,14.372 25.922,14.371 		"/>
                        <path d="M25.922,14.371C25.926,14.368,25.926,14.358,25.922,14.371L25.922,14.371z"/>
                        <path d="M29.375,4.982c-2.68-3.214-10.233-5.485-16.611-3.666C6.049,3.23,2.674,7.44,2.231,14.882
                          c-0.033,0.554-0.176,1.101-0.42,1.599L0.107,19.96c-0.134,0.272-0.143,0.594-0.025,0.875c0.119,0.28,0.353,0.498,0.643,0.594
                          l0.556,0.187c0.482,0.16,0.801,0.614,0.789,1.122c-0.15,6.657,0.194,8.354,7.237,8.354c7.507,0,6.544-5.521,15.615-6.599
                          C31.629,23.695,33.972,10.499,29.375,4.982z M27.544,13.627c0,0.424-0.339,0.745-0.765,0.745h-0.857
                          c-0.001,0.003-0.002,0.005-0.002,0.008c-0.153,0.606-0.393,1.183-0.704,1.707l0.6,0.605c0.303,0.299,0.303,0.787,0,1.087
                          l-1.104,1.106c-0.3,0.3-0.785,0.3-1.086,0l-0.603-0.604c-0.512,0.309-1.07,0.542-1.666,0.694c-0.002,0-0.004,0.437-0.01,0.854
                          c-0.002,0.422-0.345,0.76-0.767,0.76h-1.563c-0.205,0-0.397-0.08-0.545-0.227c-0.145-0.146-0.226-0.342-0.223-0.546l0.009-0.828
                          c-0.614-0.151-1.19-0.393-1.72-0.708l-0.599,0.604c-0.145,0.145-0.34,0.226-0.544,0.226s-0.399-0.081-0.543-0.226l-1.109-1.105
                          c-0.143-0.145-0.223-0.339-0.223-0.543s0.08-0.397,0.223-0.543l0.602-0.601c-0.312-0.523-0.551-1.094-0.702-1.701
                          c-0.002-0.006-0.003-0.012-0.004-0.019h-0.83c-0.423,0-0.76-0.322-0.76-0.745v-1.566c0-0.424,0.341-0.768,0.764-0.768h0.838
                          c0,0-0.001,0,0.001-0.002c0.15-0.598,0.387-1.163,0.695-1.679L13.744,9.01c-0.298-0.3-0.298-0.785,0-1.086l1.108-1.107
                          c0.145-0.144,0.34-0.224,0.544-0.224s0.399,0.081,0.544,0.224l0.6,0.601c0.53-0.317,1.106-0.581,1.722-0.711l-0.011-0.839
                          c-0.001-0.206,0.079-0.4,0.226-0.546c0.146-0.145,0.338-0.225,0.545-0.225h1.562c0.422,0,0.766,0.339,0.768,0.761
                          c0.004,0.425,0.006,0.867,0.01,0.868c0.595,0.151,1.152,0.388,1.664,0.695l0.604-0.603c0.146-0.144,0.34-0.225,0.544-0.225
                          s0.397,0.081,0.543,0.224l1.108,1.107c0.297,0.3,0.298,0.785,0,1.085L25.22,9.612c0.309,0.516,0.547,1.091,0.697,1.689
                          c0.001,0.002,0.001,0.014,0.002,0.014h0.865c0.424,0,0.763,0.323,0.763,0.747v1.565H27.544z"/>
                        <circle cx="19.781" cy="12.851" r="2.159"/>
                      </g>
                    </g>
                    </svg>
                  </div>
                </div>
                <p className="t-body-1">
                Innovative Solutions
                </p>
                <div className="c-icon strategy-arrow w-embed">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5 12L0.5 12" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"></path>
                    <path d="M13.86 19.64L21.5 12L13.86 4.36002" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"></path>
                  </svg>
                </div>
              </div>
              <div className="c-build-item is-2">
              <div className="margin-28">
                  <div className="c-icon medium w-embed">
                  <svg width="100%" fill="#4376D9" viewBox="0 -8 72 72" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M64,12.78v17s-3.63.71-4.38.81-3.08.85-4.78-.78C52.22,27.25,42.93,18,42.93,18a3.54,3.54,0,0,0-4.18-.21c-2.36,1.24-5.87,3.07-7.33,3.78a3.37,3.37,0,0,1-5.06-2.64,3.44,3.44,0,0,1,2.1-3c3.33-2,10.36-6,13.29-7.52,1.78-1,3.06-1,5.51,1C50.27,12,53,14.27,53,14.27a2.75,2.75,0,0,0,2.26.43C58.63,14,64,12.78,64,12.78ZM27,41.5a3,3,0,0,0-3.55-4.09,3.07,3.07,0,0,0-.64-3,3.13,3.13,0,0,0-3-.75,3.07,3.07,0,0,0-.65-3,3.38,3.38,0,0,0-4.72.13c-1.38,1.32-2.27,3.72-1,5.14s2.64.55,3.72.3c-.3,1.07-1.2,2.07-.09,3.47s2.64.55,3.72.3c-.3,1.07-1.16,2.16-.1,3.46s2.84.61,4,.25c-.45,1.15-1.41,2.39-.18,3.79s4.08.75,5.47-.58a3.32,3.32,0,0,0,.3-4.68A3.18,3.18,0,0,0,27,41.5Zm25.35-8.82L41.62,22a3.53,3.53,0,0,0-3.77-.68c-1.5.66-3.43,1.56-4.89,2.24a8.15,8.15,0,0,1-3.29,1.1,5.59,5.59,0,0,1-3-10.34C29,12.73,34.09,10,34.09,10a6.46,6.46,0,0,0-5-2C25.67,8,18.51,12.7,18.51,12.7a5.61,5.61,0,0,1-4.93.13L8,10.89v19.4s1.59.46,3,1a6.33,6.33,0,0,1,1.56-2.47,6.17,6.17,0,0,1,8.48-.06,5.4,5.4,0,0,1,1.34,2.37,5.49,5.49,0,0,1,2.29,1.4A5.4,5.4,0,0,1,26,34.94a5.47,5.47,0,0,1,3.71,4,5.38,5.38,0,0,1,2.39,1.43,5.65,5.65,0,0,1,1.48,4.89,0,0,0,0,1,0,0s.8.9,1.29,1.39a2.46,2.46,0,0,0,3.48-3.48s2,2.48,4.28,1c2-1.4,1.69-3.06.74-4a3.19,3.19,0,0,0,4.77.13,2.45,2.45,0,0,0,.13-3.3s1.33,1.81,4,.12c1.89-1.6,1-3.43,0-4.39Z"/></svg>
                  </div>
                </div>
                <p className="t-body-1">
                Client-Centric <br/>Approach
                </p>
                <div className="c-icon strategy-arrow w-embed">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5 12L0.5 12" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"></path>
                    <path d="M13.86 19.64L21.5 12L13.86 4.36002" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"></path>
                  </svg>
                </div>
              </div>
              <div className="c-build-item">
              <div className="margin-28">
                  <div className="c-icon medium w-embed">
                    <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                      width="100%" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlSpace="preserve">
                    <path fill="#4376D9" d="M62.799,23.737c-0.47-1.399-1.681-2.419-3.139-2.642l-16.969-2.593L35.069,2.265
                      C34.419,0.881,33.03,0,31.504,0c-1.527,0-2.915,0.881-3.565,2.265l-7.623,16.238L3.347,21.096c-1.458,0.223-2.669,1.242-3.138,2.642
                      c-0.469,1.4-0.115,2.942,0.916,4l12.392,12.707l-2.935,17.977c-0.242,1.488,0.389,2.984,1.62,3.854
                      c1.23,0.87,2.854,0.958,4.177,0.228l15.126-8.365l15.126,8.365c0.597,0.33,1.254,0.492,1.908,0.492c0.796,0,1.592-0.242,2.269-0.72
                      c1.231-0.869,1.861-2.365,1.619-3.854l-2.935-17.977l12.393-12.707C62.914,26.68,63.268,25.138,62.799,23.737z"/>
                    </svg>
                  </div>
                </div>
                <p className="t-body-1">
                Commitment to Excellence
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesDetails;