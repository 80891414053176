import React from "react";
import HeroBanner from './HeroBanner';
import backgroundImage from '../images/digital-bg-03.jpg';
import logoImage from '../images/eje-digital-logo.png';

const DigitalHero = () => {
  return (
    <>
    <HeroBanner
      backgroundImage={backgroundImage}
      logoImage={logoImage}
      title="Digital"
    />
    </>
  )
}

export default DigitalHero;