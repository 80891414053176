import React from "react";
import './TeamSection.css';
import LamiaPfp from '../images/team/1715261584336.jpg';
import OussamaPfp from '../images/team/1711828045707.jpg';
import AhlamPfp from '../images/team/1701944311092.jpg';
import AzizaPfp from '../images/team/1710379609099.jpg';
import LahbibPfp from '../images/team/1679526405144.jpg';
import AalaPfp from '../images/team/1699707127769.jpg';
import RachidPfp from '../images/team/1681872417089.jpg';
import YoussefPfp from '../images/team/1637755280701.jpg';
import YassirPfp from '../images/team/1697643079527.jpg';
import AyaPfp from '../images/team/1721990072294.jpg';
import MyriamPfp from '../images/team/1696101450415.jpg';
import ZiadPfp from '../images/team/1702117806882.jpg';
import AhmedPfp from '../images/team/1710360210781.jpg';
import ZakariaPfp from '../images/team/1709371453815.jpg';
import AnasPfp from '../images/team/1671362661420.jpg';
import JihanePfp from '../images/team/1718829567734.jpg';


const TeamSection = () => {
  return (
    <>
      <section className="c-section team-section">
        <div className="o-container">
          <h1 className="t-display-1 is-serif">
            EJE Mandate 24/25
          </h1>
          <div id="w-node-b1158db7-c8c3-03e2-77dc-ecc0854be950-94802662" className="w-dyn-list">
            <div role="list" className="o-grid members w-dyn-items">
              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/lamiae-bidah-072482166/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={LamiaPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                    Lamiae Bidah
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Executive President
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>
              
              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/ahlam-soulmani-b0b7a422b/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={AhlamPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                    Ahlam Soulmani
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                    Vice President
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/aziza-harmouche-5aa25426a/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={AzizaPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Aziza Harmouche
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Secretary General & Treasurer
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/lahbib-allali/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={LahbibPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Lahbib Allali
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate quality and recruitment director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/aala-eddine-m-435011209/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={AalaPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Aala Eddine Maggouri
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate quality and recruitment director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/rachid-ait-jalloul-56497a225/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={RachidPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Rachid Ait Jalloul
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate quality and recruitment director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/youssef-adouiri/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={YoussefPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Youssef Adouiri Alaoui
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate quality and recruitment director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/yassir-el-qaqi/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={YassirPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Yassir Elqaqi
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate quality and recruitment director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/aya-jaafar-5ba943254/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={AyaPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Aya JAAFAR
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Director of Prospecting & Partnerships
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/myriam-ottmani-157966287/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={MyriamPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Myriam Ottmani
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate director of Prospecting & Partnerships
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/ziad-ait-belaissaoui-876259226/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={ZiadPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Ziad Ait Bellaissaoui
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate event director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/ahmed-lebbar-3400bb255/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={AhmedPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Ahmed Lebbar
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate event director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/zakariae-ennacher-50a8b3283/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={ZakariaPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Zakariae Ennacher
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate event director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/oussama-el-mahboubi-99a8402b8/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={OussamaPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                    Oussama El Mahboubi
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                    Communications Director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/anas-rouam-3a7a20178/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={AnasPfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Anas Rouam
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Training Director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

              <div role="listitem" className="c-partner-item w-dyn-item">
                <div className="c-partner-trigger"></div>
                <a className="c-btn partner-tag w-inline-block" href="https://www.linkedin.com/in/jihane-jra-b46357314/">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Linkedin</p>
                      <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
                <div className="c-img-contain margin-24 br-16">
                  <img loading="lazy" src={JihanePfp} className="c-img"></img>
                </div>
                <div className="margin-24">
                  <div className="t-body-1">
                  Jihane Jra
                  </div>
                </div>
                <div className="margin-4">
                  <div className="t-body-3">
                  Associate training Director
                  </div>
                </div>
                <div className="c-line partner"></div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamSection;