import React, { useRef, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import './styles.css';
import MapMarker from '../images/map-marker.png';

const MapComponent = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAKY0XVcewxSJpjkFRRRs7mpSqkpRnodmg',
      version: 'weekly',
    });

    loader.load().then(() => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 32.22020020043933, lng: -7.935956048023072 },
        zoom: 7,
        minZoom: 7, 
        maxZoom: 18,
        gestureHandling: 'none', 
      });

      new window.google.maps.Marker({
        position: { lat: 32.22020020043933, lng: -7.935956048023072 },
        map,
        icon: MapMarker,
      });
    });
  }, []);

  return (
      <div id="map" ref={mapRef}></div>
  );
};

export default MapComponent;
