import React, { useEffect, useState } from 'react';
import './styles.css'; 
import GlassCubes from '../images/glass-cubes-02.png';
import GlassCubesMobile from '../images/glass-cubes-02-mobile.png';
import Ribbons from './Ribbons';
import './styles-hero.css';
import DiagArrow from '../images/diagonal-arrow-svgrepo-com.svg';

const CHero = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  useEffect(() => {
    // Function to handle resizing
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className='c-section hero'>
      <div className='c-img-contain hm-hero-bg hide-mobile'>
        <Ribbons />
      </div>

      <div className='o-container'>
        <div className='o-row'>
          {/* Mobile image */}
          <div className='o-col image-column show-mobile'>
            <img 
              src={isMobile ? GlassCubesMobile : GlassCubes} 
              alt='Descriptive Alt Text' 
              className='column-image' 
            />
          </div>

          {/* Text content */}
          <div className='o-col text-column'>
            <div className='text-container'>
              <h1 className='t-display-1 is-serif'>
                {[
                  'Innovation',
                  'and',
                  'Creativity',
                  'by',
                  'junior',
                  'talents',
                  'at',
                  'your',
                  'company\'s',
                  'service'
                ].map((word, wordIndex, wordArray) => (
                  <div
                    className={`word ${word === 'your' ? 'highlight-word' : ''}`}
                    key={wordIndex}
                  >
                    {word.split('').map((char, charIndex) => (
                      <div
                        className={`char`}
                        key={charIndex}
                        style={{ '--char-index': wordIndex * 10 + charIndex }}
                      >
                        {char}
                      </div>
                    ))}
                    &nbsp;
                  </div>
                ))}
              </h1>
              <p className='t-body-2 fade-in'>
                We are a team of highly motivated and skilled engineering students dedicated to delivering only the best service.
              </p>
              <div className='btn-container fade-in'>
                <a className='c-btn-hero' href='/services'>
                  <div className='c-btn-hero-inner'>
                    <img src={DiagArrow} className='w-inline-block box-arrow' alt="arrow"></img>
                    <p className='t-body-3 w-inline-block'>Discover our services</p>
                  </div>
                </a>
                <a className='c-btn-hero' href='https://meetings-eu1.hubspot.com/el-mahboubi' target='_blank' rel='noopener noreferrer'>
                  <div className='c-btn-hero-inner'>
                    <img src={DiagArrow} className='w-inline-block box-arrow' alt="arrow"></img>
                    <p className='t-body-3 w-inline-block'>Book a meeting</p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Desktop image */}
          <div className='o-col image-column hide-mobile'>
            <img 
              src={isMobile ? GlassCubesMobile : GlassCubes} 
              alt='Descriptive Alt Text' 
              className='column-image' 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CHero;
