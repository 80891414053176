import React, { useState } from 'react';
import NavBar2 from '../components/Navbar/NavBar2';
import BusinessHero from '../components/BusinessHero';
import BusinessDetails from '../components/BusinessDetails';
import Footer from '../components/Footer';

import {useDocTitle} from '../components/CustomHook';


const Business = () => {
    useDocTitle('EJE Business')

    return (
        <div className='business-page'>  
          <NavBar2 />
          <BusinessHero />
          <BusinessDetails />
          <Footer />
        </div>


    )
}

export default Business;