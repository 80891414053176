import React, { useState } from 'react';
import NavBar2 from '../components/Navbar/NavBar2';
import TeamHero from '../components/TeamHero';
import TeamSection from '../components/TeamSection';
import Footer from '../components/Footer';

import {useDocTitle} from '../components/CustomHook';


const Team = () => {
    useDocTitle('Team')

    return (
        <div className='team-page'>  
          <NavBar2 />
          <TeamHero />
          <TeamSection />
          <Footer />
        </div>


    )
}

export default Team;