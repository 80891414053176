import React, { useState } from 'react';
import NavBar2 from '../components/Navbar/NavBar2';
import Footer from '../components/Footer';
import HeroServices from '../components/HeroServices';
import ServicesDetails from '../components/ServicesDetails';
import {useDocTitle} from '../components/CustomHook';


const Services = () => {
    useDocTitle('Services')

    return (
        <div className='services-page'>  
          <NavBar2 />
          <HeroServices />
          <ServicesDetails />
          <Footer />
        </div>


    )
}

export default Services;