import React, { useEffect } from 'react';
import './testimants.css'; 
import TestimonialImg1 from '../images/feedbacks/hicham_louah.jpeg';
import TestimonialImg2 from '../images/feedbacks/maryam_kouram.jpeg';

const Testimonials = () => {
  useEffect(() => {
    const slider = document.querySelector('[data-carousel]');
    const slides = [...document.querySelectorAll('.Wallop-item')];
    const wallop = new window.Wallop(slider);

    let prev = 0;

    const removePrevClasses = (index) => {
      let prevClass;
      if (slides[index].classList.contains('Wallop-item--hidePrevious')) {
        prevClass = 'Wallop-item--hidePrevious';
      } else if (slides[index].classList.contains('Wallop-item--hideNext')) {
        prevClass = 'Wallop-item--hideNext';
      }

      if (prevClass) {
        setTimeout(() => {
          slides[index].classList.remove(prevClass);
        }, 600);
      }
    };

    const onChange = () => {
      removePrevClasses(prev);
      prev = wallop.currentItemIndex;
    };

    wallop.on('change', onChange);
  }, []);

  return (
    <div className='row testimonials'>
    <div className="wrapper">
      <div data-carousel>
        <ul className="slide__list Wallop-list">
          <li className="slide__item Wallop-item Wallop-item--current">
            <div className='row slide__heading'>
              <div className='person-info'>
                <img src={TestimonialImg1} className='person-photo' alt='Person Photo'></img>
                <div className='person-details'>
                  <span className='person-name'>Hicham LOUAH</span>
                  <span className='person-profession'>Head of To FREEARTEC</span>
                </div>
              </div>
            </div>
            <blockquote>
              <p className="slide__quote"><i class="fa-solid fa-quote-left"></i> On behalf of the company To FREEARTEC, we are very satisfied with the professional work of the EJE team. Thank you very much for your fertile collaboration. <i class="fa-solid fa-quote-right"></i></p>
              <cite className="slide__cite">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star-half-stroke"></i>
              </cite>
            </blockquote>
          </li>
          <li className="slide__item Wallop-item">
            <div className='row slide__heading'>
              <div className='person-info'>
                <img src={TestimonialImg2} className='person-photo' alt='Person Photo'></img>
                <div className='person-details'>
                  <span className='person-name'>Maryame Kouram</span>
                  <span className='person-profession'>Consulting Advisor at OCP Group</span>
                </div>
              </div>
            </div>
            <blockquote>
              <p className="slide__quote"><i class="fa-solid fa-quote-left"></i>  Working with the P-Curiosity Lab was an outstanding experience. It was a pleasure to contribute to the growth of the PCL community on social media. The exchanges with the PCL team were also very informative and helped me understand their needs and vision. <i class="fa-solid fa-quote-right"></i></p>
              <cite className="slide__cite">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </cite>
            </blockquote>
          </li>
          <li className="slide__item Wallop-item">
            <div className='row slide__heading'>
              <div className='person-info'>
                <img src={TestimonialImg2} className='person-photo' alt='Person Photo'></img>
                <div className='person-details'>
                  <span className='person-name'>Maryame Kouram</span>
                  <span className='person-profession'>Consulting Advisor at OCP Group</span>
                </div>
              </div>
            </div>
            <blockquote>
              <p className="slide__quote"><i class="fa-solid fa-quote-left"></i>  Working with the P-Curiosity Lab was an outstanding experience. It was a pleasure to contribute to the growth of the PCL community on social media. The exchanges with the PCL team were also very informative and helped me understand their needs and vision. <i class="fa-solid fa-quote-right"></i></p>
              <cite className="slide__cite">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </cite>
            </blockquote>
          </li>
        </ul>
        <button className="button--prev Wallop-buttonPrevious" title="previous">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.9 50.2"><path d="M25.1 50.2L0 25.1 25.1 0l2.8 2.8L5.7 25.1l22.2 22.2z"/></svg>
        </button>
        <button className="button--next Wallop-buttonNext" title="next">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.9 50.2"><path d="M25.1 50.2L0 25.1 25.1 0l2.8 2.8L5.7 25.1l22.2 22.2z"/></svg>
        </button>
      </div>
    </div>
    </div>
  );
};

export default Testimonials;
