import React, { useState, useEffect, useRef } from 'react';
import './footer.css';
import EJElogo from './eje-logo.png';


const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
    const [isOpen2, setIsOpen2] = useState(false);

    const handleToggle2 = () => {
      setIsOpen2(!isOpen2);
    };
    const [isOpen3, setIsOpen3] = useState(false);

    const handleToggle3 = () => {
      setIsOpen3(!isOpen3);
    };

    useEffect(() => {
        const content = contentRef.current;
    
        if (isOpen) {
          content.style.height = `${content.scrollHeight}px`;
          const handleTransitionEnd = () => {
            content.style.height = 'auto';
            content.removeEventListener('transitionend', handleTransitionEnd);
          };
          
          content.addEventListener('transitionend', handleTransitionEnd);
        } else {
          content.style.height = `${content.scrollHeight}px`;
          requestAnimationFrame(() => {
            content.style.height = '0';
          });
        }
      }, [isOpen]);

      useEffect(() => {
        const content2 = contentRef2.current;
    
        if (isOpen2) {
          content2.style.height = `${content2.scrollHeight}px`;
          const handleTransitionEnd = () => {
            content2.style.height = 'auto';
            content2.removeEventListener('transitionend', handleTransitionEnd);
          };
          
          content2.addEventListener('transitionend', handleTransitionEnd);
        } else {
          content2.style.height = `${content2.scrollHeight}px`;
          requestAnimationFrame(() => {
            content2.style.height = '0';
          });
        }
      }, [isOpen2]);

      useEffect(() => {
        const content3 = contentRef3.current;
    
        if (isOpen3) {
          content3.style.height = `${content3.scrollHeight}px`;
          const handleTransitionEnd = () => {
            content3.style.height = 'auto';
            content3.removeEventListener('transitionend', handleTransitionEnd);
          };
          
          content3.addEventListener('transitionend', handleTransitionEnd);
        } else {
          content3.style.height = `${content3.scrollHeight}px`;
          requestAnimationFrame(() => {
            content3.style.height = '0';
          });
        }
      }, [isOpen3]);

  return (
    <div className='c-section footer'>
      <div className='o-container'>
        <div className='o-row footer_top'>
            <div draw-vertical-line className='c-line vertical-divider footer'></div>
            <div data-margin="80-sm" className='o-col _w-6 md-w-24'>
                <div className='c-img-contain logo-footer'>
                    <img src={EJElogo} loading='lazy' className='c-img'></img>
                </div>
            </div>
            <div data-margin="64-sm" className='o-col _w-6 md-w-24'>
                <div className='margin-12'>
                    <div className='t-micro-2'>contact</div>
                </div>
                <a href='mailto:eje@emines.um6p.ma' className='w-inline-block'>
                    <div className='t-body-2'>eje@emines.um6p.ma</div>
                </a>
            </div>
            <div className='o-col _w-4 md-w-24'>
                <div className='c-footer-list portfolio accordion-el'>
                    <div className='c-footer-list_top'>
                        <div className='t-micro-2'>services</div>
                        <div className={`c-icon footer-accordion w-embed ${isOpen ? 'is-open' : ''}`} onClick={handleToggle}>
                            <svg width="20" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.1875 8.02173L8.1875 14.0217L6.25 14.0217L6.25 8.02173L0.4375 8.02173L0.4375 6.02173L6.25 6.02173L6.25 0.0217288L8.1875 0.021729L8.1875 6.02173L14 6.02173L14 8.02173L8.1875 8.02173Z" fill="#EAE7D7">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div className={`c-footer-list_bt w-dyn-list ${isOpen ? 'is-open' : ''}`} ref={contentRef}>
                        <div role='list' className='c-footer-list_bt-inner w-dyn-items'>
                            <div role='listitem' className='c-footer-item w-dyn-item'>
                                <div className='w-embed'>
                                    <a className='t-body-5 opacity-70' href='/services/business'>Business</a>
                                </div>
                            </div>
                            <div role='listitem' className='c-footer-item w-dyn-item'>
                                <div className='w-embed'>
                                    <a className='t-body-5 opacity-70' href='/services/digital'>Digital</a>
                                </div>
                            </div>
                            <div role='listitem' className='c-footer-item w-dyn-item'>
                                <div className='w-embed'>
                                    <a className='t-body-5 opacity-70' href='/services/analytics'>Analytics</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='o-col _w-4 md-w-24'>
                <div className='c-footer-list accordion-el'>
                    <div className='c-footer-list_top'>
                        <div className='t-micro-2'>EJE</div>
                        <div className={`c-icon footer-accordion w-embed ${isOpen2 ? 'is-open' : ''}`} onClick={handleToggle2}>
                            <svg width="20" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.1875 8.02173L8.1875 14.0217L6.25 14.0217L6.25 8.02173L0.4375 8.02173L0.4375 6.02173L6.25 6.02173L6.25 0.0217288L8.1875 0.021729L8.1875 6.02173L14 6.02173L14 8.02173L8.1875 8.02173Z" fill="#EAE7D7">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div className={`c-footer-list_bt ${isOpen2 ? 'is-open' : ''}`} ref={contentRef2}>
                        <div className='c-footer-list_bt-inner'>
                            <div className='c-footer-item'>
                                <a href='/#about' className='w-inline-block'>
                                    <div className='t-body-5 opacity-70'>
                                        About us
                                    </div>
                                </a>
                            </div>
                            <div className='c-footer-item'>
                                <a href='/team' className='w-inline-block'>
                                    <div className='t-body-5 opacity-70'>
                                        Team
                                    </div>
                                </a>
                            </div>
                            <div className='c-footer-item'>
                                <a href='/#events' className='w-inline-block'>
                                    <div className='t-body-5 opacity-70'>
                                        Events
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='o-col _w-4 md-w-24'>
                <div className='c-footer-list accordion-el'>
                    <div className='c-footer-list_top'>
                        <div className='t-micro-2'>media</div>
                        <div className={`c-icon footer-accordion w-embed ${isOpen3 ? 'is-open' : ''}`} onClick={handleToggle3}>
                            <svg width="20" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.1875 8.02173L8.1875 14.0217L6.25 14.0217L6.25 8.02173L0.4375 8.02173L0.4375 6.02173L6.25 6.02173L6.25 0.0217288L8.1875 0.021729L8.1875 6.02173L14 6.02173L14 8.02173L8.1875 8.02173Z" fill="#EAE7D7">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div className={`c-footer-list_bt ${isOpen3 ? 'is-open' : ''}`} ref={contentRef3}>
                        <div className='c-footer-list_bt-inner'>
                            <div className='c-footer-item'>
                                <a className='w-inline-block' href='https://www.facebook.com/eminesjuniorentreprise/'>
                                    <div className='t-body-5 opacity-70'>Facebook</div>
                                </a>
                            </div>
                            <div className='c-footer-item'>
                                <a className='w-inline-block' href='https://www.instagram.com/eminesjuniorentreprise/'>
                                    <div className='t-body-5 opacity-70'>Instagram</div>
                                </a>
                            </div>
                            <div className='c-footer-item'>
                                <a className='w-inline-block' href='https://www.linkedin.com/company/emines-junior-entreprise'>
                                    <div className='t-body-5 opacity-70'>LinkedIn</div>
                                </a>
                            </div>
                            <div className='c-footer-item'>
                                <a className='w-inline-block' href='#'>
                                    <div className='t-body-5 opacity-70'>YouTube</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='o-row footer-bt'>
            <div className='c-line footer_bt'></div>
            <div className='c-footer_bt-list'>
                <div className='t-micro-2 small opacity-60'><i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Ben Guerir, Morocco</div>
            </div>
            <div className='c-footer_bt-list'>
                <div className='c-footer-item centered'>
                    <div className='t-micro-2 small opacity-60'>©&nbsp;
                        <span>2024&nbsp;</span>
                        Emines Junior Entreprise
                    </div>
                </div>
            </div>
            <div className='c-footer_bt-list'>
                <div className='c-footer-item'>
                    <a href='https://www.linkedin.com/in/oussama-el-mahboubi-99a8402b8/' className='w-inline-block' target='_blank'>
                        <div className='t-micro-2 small opacity-60'>Site by Oussama El Mahboubi</div>
                    </a>
                </div>
                <div className='c-footer-item'>
                    <a href='/tos' className='w-inline-block' target='_blank'>
                        <div className='t-micro-2 small opacity-60'>Privacy Policy & Terms</div>
                    </a>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
