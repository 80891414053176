import React, { useState } from 'react';
import NavBar2 from '../components/Navbar/NavBar2';
import AnalyticsHero from '../components/AnalyticsHero';
import AnalyticsDetails from '../components/AnalyticsDetails';
import Footer from '../components/Footer';

import {useDocTitle} from '../components/CustomHook';


const Analytics = () => {
    useDocTitle('EJE Analytics')

    return (
        <div className='analytics-page'>  
          <NavBar2 />
          <AnalyticsHero />
          <AnalyticsDetails />
          <Footer />
        </div>


    )
}

export default Analytics;