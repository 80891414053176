import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Events.css';
import Event1 from '../images/events/sch-event.jpg';
import Event2 from '../images/events/opx-event.jpg';
import Event3 from '../images/events/meet-the-leader-event.jpg';
import SchLogo from '../images/events/sch-logo.png';
import OpxLogo from '../images/events/opx-logo.png';
import MtlLogo from '../images/events/meet_the_leader.png';


const PrevArrow = (props) => (
  <button
      type='button'
      className='slick-prev slick-arrow'
      onClick={props.onClick}
  >
      <i className='fa fa-angle-left' aria-hidden='true'></i>
  </button>
);

const NextArrow = (props) => (
  <button
      type='button'
      className='slick-next slick-arrow'
      onClick={props.onClick}
  >
      <i className='fa fa-angle-right' aria-hidden='true'></i>
  </button>
);

const Events = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      handleResize(); 
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
      dots: false,
      infinite: windowWidth < 991,
      speed: 500,
      slidesToShow: windowWidth >= 991 ? 3 : windowWidth > 575 ? 3 : 1,
      slidesToScroll: 1,
      centerMode: windowWidth < 575,
      centerPadding: windowWidth < 575 ? '50px' : '0px',
      swipe: windowWidth < 991,
      arrows: windowWidth < 991,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
  };
  return (
    <>
    <div className="c-line"></div>
    <div className='c-section events' id='events'>
      <div className='o-container'>
       <div className='f-container '>
          <div className="o-row margin-64">
            <div className="o-col _w-16 sm-w-24">
                <div className='t-micro-1'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;events</div>
              <h2 className="t-display-2 is-serif">Made by EJE,<br/>at EMINES</h2>   
            </div>
          </div>
                <div className='inner-container'>
                        <Slider {...settings} key={windowWidth}>
                            <div className='img-container'>
                                <img src={Event1} alt='product-image'/>
                                <div className='overlay'>
                                    <div className='content sch'>
                                        <h5>SCH</h5>
                                        <p className='t-body-6'>
                                        SCH is a yearly event organized by EJE at Emines, where teams of students from different engineering and business schools in Morocco compete in a consulting themed hackathon to tackle real world problems.
                                        </p>
                                    </div>
                                    <div className='event-logo-container'>
                                            <img src={SchLogo} alt='sch-logo' className='event-logo'/>
                                    </div>
                                </div>
                            </div>
                            <div className='img-container'>
                                <img src={Event2} alt='product-image'/>
                                <div className='overlay'>
                                    <div className='content opx'>
                                        <h5>OPX</h5>
                                        <p className='t-body-6'>
                                        OPX is a yearly event organized by EJE at Emines, where teams of students from different engineering and business schools in Morocco compete in an operational excellence themed hackathon to tackle real world problems.
                                        </p>
                                    </div>
                                    <div className='event-logo-container'>
                                            <img src={OpxLogo} alt='opx-logo' className='event-logo'/>
                                    </div>
                                </div>
                            </div>
                            <div className='img-container'>
                                <img src={Event3} alt='product-image'/>
                                <div className='overlay'>
                                    <div className='content mtl'>
                                        <h5>Meet The Leader</h5>
                                        <p className='t-body-6'>
                                        Meet the Leader is an event organized by EJE at Emines, where professionals from big companies share their expertise with Emines students during a series of educational conferences.
                                        </p>
                                    </div>
                                    <div className='event-logo-container'>
                                            <img height={'100px'} src={MtlLogo} alt='mtl-logo' className='event-logo'/>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                </div>
            </div>
            </div>
            </div>
    </>
  )
}

export default Events;