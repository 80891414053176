import React, { useState, useEffect, useRef } from 'react';
import './NavBar2.css';
import ArrowIcon from '../../images/icon-arrow-white.svg';

const NavBar2 = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [sideOpen, setSideOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [formStatus, setFormStatus] = useState('');
  const contentRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleSide = () => {
    setSideOpen(!sideOpen);
  };
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [menuOpen]);

  document.addEventListener('DOMContentLoaded', function() {
    const dropdownHead = document.querySelector('.navbar_dropdown-head');
    const dropdownContent = document.querySelector('.navbar_dropdown-content');
    const arrow = document.querySelector('.navbar_dropdown-arrow');
    
    let timer;
    
    dropdownHead.addEventListener('mouseenter', function() {
      clearTimeout(timer);
      dropdownContent.style.display = 'flex';
      setTimeout(() => {
        dropdownContent.classList.add('visible');
        arrow.style.transform = 'rotateZ(180deg)';
      }, 10);
    });
  
    dropdownHead.addEventListener('mouseleave', function() {
      timer = setTimeout(() => {
        dropdownContent.classList.remove('visible');
        arrow.style.transform = 'rotateZ(0deg)';
        setTimeout(() => {
          dropdownContent.style.display = 'none';
        }, 300);
      }, 300); 
    });
  
    dropdownContent.addEventListener('mouseenter', function() {
      clearTimeout(timer);
    });
  
    dropdownContent.addEventListener('mouseleave', function() {
      timer = setTimeout(() => {
        dropdownContent.classList.remove('visible');
        arrow.style.transform = 'rotateZ(0deg)';
        setTimeout(() => {
          dropdownContent.style.display = 'none';
        }, 300); 
      }, 300); 
    });
  });


  useEffect(() => {
    const content = contentRef.current;

    if (sideOpen) {
      content.style.height = `${content.scrollHeight}px`;
      const handleTransitionEnd = () => {
        content.style.height = 'auto';
        content.removeEventListener('transitionend', handleTransitionEnd);
      };
      
      content.addEventListener('transitionend', handleTransitionEnd);
    } else {
      content.style.height = `${content.scrollHeight}px`;
      requestAnimationFrame(() => {
        content.style.height = '0';
      });
    }
  }, [sideOpen]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements['Email-2'].value;
  
    try {
      const response = await fetch('https://eje-backend-sep-8fa22d620623.herokuapp.com/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        setFormStatus('success');
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      setFormStatus('error');
    }
  };
  

  return (
    <>
    <header className={`c-nav-section ${scrolled ? 'scrolled' : ''} ${menuOpen ? 'open' : ''}`} ref={navRef}>
      <div className='c-header-bg'></div>
        <div className='c-header-inner'>
          <div className='c-header_lt'>
          <div className={`c-nav-btn wide-screen-vw ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className='c-nav-icon'>
                <div className='c-nav-icon-bar'></div>
                <div className='c-nav-icon-bar'></div>
                <div className='c-nav-icon-bar'></div>
              </div>
            </div>
            <a href='/' aria-current='page' className='c-logo-link w--current'>
              <div className='c-logo-container'>
                <a className='navbar-brand' href='/'>
                  <h1 className='logo'>EJE</h1>
                </a>
                <h1 className='logo-description'>EMINES<br />JUNIOR<br />ENTREPRISE</h1>
              </div>
            </a>
          </div>
          <div className={`c-header_center`}>
            <nav className={`c-header-nav`}>
              <a href='/#about' className='c-nav-link'>About us</a>
              <link rel='prefetch' href='/#about'></link>
              <div className='navbar_dropdown'>
                  <div className='navbar_dropdown-head'>
                    <div className='navbar_link-text'>
                      <a href='/services' className='c-nav-link'>
                      Services
                      </a>
                    </div>
                    <div className='navbar_dropdown-arrow w-embed'>
                      <svg width={'25'} height={'24'} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M13.2199 15.7802C13.0793 15.9209 12.8887 16 12.6899 16.0002H12.3099C12.1114 15.9979 11.9216 15.919 11.7799 15.7802L6.64985 10.6402C6.5552 10.5463 6.50195 10.4185 6.50195 10.2852C6.50195 10.1518 6.5552 10.024 6.64985 9.93017L7.35985 9.22017C7.45202 9.1261 7.57816 9.07309 7.70985 9.07309C7.84154 9.07309 7.96769 9.1261 8.05985 9.22017L12.4999 13.6702L16.9399 9.22017C17.0337 9.12551 17.1615 9.07227 17.2949 9.07227C17.4282 9.07227 17.556 9.12551 17.6499 9.22017L18.3499 9.93017C18.4445 10.024 18.4978 10.1518 18.4978 10.2852C18.4978 10.4185 18.4445 10.5463 18.3499 10.6402L13.2199 15.7802Z' fill='currentcolor'></path>
                      </svg>
                    </div>
                  </div>  
                  <div className='navbar_dropdown-content arrow-up'>
                      
                      <div className='navbar_dropdown-links'>
                        <a href='/services/business' className='navbar_link w-inline-block'>
                          <div className='c-nav-link'>Business</div>
                          
                        </a>
                        <a href='/services/digital' className='navbar_link w-inline-block'>
                          <div className='c-nav-link'>Digital</div>
                          
                        </a>
                        <a href='/services/analytics' className='navbar_link w-inline-block'>
                          <div className='c-nav-link'>Analytics</div>
                        
                        </a>
                      </div>
                    </div>
              </div>
              <a href='/team' className='c-nav-link'>Team</a>
              <link rel='prefetch' href='/team'></link>
              <a href='/#events' className='c-nav-link'>Events</a>
              <link rel='prefetch' href='/#events'></link>
            </nav>
          </div>
          <div className='c-header_rt'>
            <a className='contact-btn-bsc' href='/contact'>
              <div className='c-btn-inner'>
                <p className='t-body-4'>Contact us</p>
              </div>
            </a>
            <link rel='prefetch' href='/contact'></link>
            <div className={`c-nav-btn tablet-vw ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className='c-nav-icon'>
                <div className='c-nav-icon-bar'></div>
                <div className='c-nav-icon-bar'></div>
                <div className='c-nav-icon-bar'></div>
              </div>
            </div>
          </div>
        </div>
      <div className='c-line header'></div>
    </header>
    <div className={`navbar-menu ${menuOpen ? 'open' : ''} ${sideOpen ? 'sideOpen' : ''}`}>
    <div className='navbar-menu_middle'>
      <div className='w-layout-grid navbar-menu_middle-wrapper'>
        <div className='w-layout-grid navbar-menu_list' id='w-node-c9e34a5b-ccdb-8e65-b6ea-92b307b110a2-07b11087'>
          <a className='navbar-menu_link w-inline-block' href='/#about'>
            <div className='navbar-menu_link-text-small'>01 /</div>
            <div className='navbar-menu_link-text-large'>About us</div>
          </a>
          <div className='navbar-menu_link w-inline-block'>
            <a href='/services' style={{display: 'flex'}}>
            <div className='navbar-menu_link-text-small'>02 /</div>
            <div className='navbar-menu_link-text-large'>Services</div>
            </a>
            <div className={`navbar_dropdown-arrow w-embed ${menuOpen ? 'open' : ''}`} onClick={toggleSide}>
                      <svg width={'40'} height={'39'} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M13.2199 15.7802C13.0793 15.9209 12.8887 16 12.6899 16.0002H12.3099C12.1114 15.9979 11.9216 15.919 11.7799 15.7802L6.64985 10.6402C6.5552 10.5463 6.50195 10.4185 6.50195 10.2852C6.50195 10.1518 6.5552 10.024 6.64985 9.93017L7.35985 9.22017C7.45202 9.1261 7.57816 9.07309 7.70985 9.07309C7.84154 9.07309 7.96769 9.1261 8.05985 9.22017L12.4999 13.6702L16.9399 9.22017C17.0337 9.12551 17.1615 9.07227 17.2949 9.07227C17.4282 9.07227 17.556 9.12551 17.6499 9.22017L18.3499 9.93017C18.4445 10.024 18.4978 10.1518 18.4978 10.2852C18.4978 10.4185 18.4445 10.5463 18.3499 10.6402L13.2199 15.7802Z' fill='currentcolor'></path>
                      </svg>
                    </div>
          </div>
          <div className={`w-layout-grid navbar-menu_list show-mobile ${sideOpen ? 'sideOpen' : ''}`} id='w-node-c9e34a5b-ccdb-8e65-b6ea-92b307b110b7-07b11087' ref={contentRef}>
        <a className='navbar-menu_link w-inline-block' href='/services/business'>
            <div className='navbar-menu_link-text-small'>02-01 /</div>
            <div className='navbar-menu_link-text-large'>Business</div>
          </a>
          <a className='navbar-menu_link w-inline-block' href='/services/digital'>
            <div className='navbar-menu_link-text-small'>02-02 /</div>
            <div className='navbar-menu_link-text-large'>Digital</div>
          </a>
          <a className='navbar-menu_link w-inline-block' href='/services/analytics'>
            <div className='navbar-menu_link-text-small'>02-03 /</div>
            <div className='navbar-menu_link-text-large'>Analytics</div>
          </a>
        </div>
          <a className='navbar-menu_link w-inline-block' href='/team'>
            <div className='navbar-menu_link-text-small'>03 /</div>
            <div className='navbar-menu_link-text-large'>Team</div>
          </a>
          <a className='navbar-menu_link w-inline-block' href='/#events'>
            <div className='navbar-menu_link-text-small'>04 /</div>
            <div className='navbar-menu_link-text-large'>Events</div>
          </a>
        </div>
        <div className={`w-layout-grid navbar-menu_list hide-mobile ${sideOpen ? 'sideOpen' : ''}`} id='w-node-c9e34a5b-ccdb-8e65-b6ea-92b307b110b7-07b11087'>
        <a className='navbar-menu_link w-inline-block' href='/services/business'>
            <div className='navbar-menu_link-text-small'>02-01 /</div>
            <div className='navbar-menu_link-text-large'>Business</div>
          </a>
          <a className='navbar-menu_link w-inline-block' href='/services/digital'>
            <div className='navbar-menu_link-text-small'>02-02 /</div>
            <div className='navbar-menu_link-text-large'>Digital</div>
          </a>
          <a className='navbar-menu_link w-inline-block' href='/services/analytics'>
            <div className='navbar-menu_link-text-small'>02-03 /</div>
            <div className='navbar-menu_link-text-large'>Analytics</div>
          </a>
        </div>
        
      </div>
    </div>
    <div className='navbar-menu_bottom'>
    <div className='navbar-menu_newsletter-cta-wrapper'>
    <a 
      href='#' 
      className='navbar-menu_newsletter-cta w-inline-block' 
      style={{ display: isActive ? 'none' : 'block' }} 
      onClick={() => setIsActive(!isActive)}
    >
      <div>Sign up for EJE Newsletter</div>
    </a>
    <div className="navbar-menu_newsletter w-form" style={{ display: isActive ? 'block' : 'none' }}>
      {formStatus === '' && (
        <form id="email-form" name="email-form" method="get" className="navbar-menu_form" onSubmit={handleSubmit}>
          <input className="newsletter-form-input w-input" maxLength="256" name="Email-2" placeholder="Enter email" type="email" id="Email-2" required />
          <button type="submit">
            <img src={ArrowIcon} loading="lazy" alt="" className="newsletter-arrow-icon"/>
          </button>
        </form>
      )}
      {formStatus === 'success' && (
        <div className="navbar-menu_success-message w-form-done">
          <div>Thank you! You've subscribed to the EJE newsletter</div>
        </div>
      )}
      {formStatus === 'error' && (
        <div className="navbar-menu_error-message w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      )}
    </div>
  </div>

      <div className='w-layout-grid navbar-menu_social-links-list'>
          <a className='navbar-menu_social-link w-inline-block' href='https://www.linkedin.com/company/emines-junior-entreprise'>
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a className='navbar-menu_social-link w-inline-block' href='https://www.instagram.com/eminesjuniorentreprise/'>
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a className='navbar-menu_social-link w-inline-block' href=''>
            <i className="fa-brands fa-youtube"></i>
          </a>
        </div>
    </div>
  </div>
  </>
  );
};

export default NavBar2;
