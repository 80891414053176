import React from "react";
import './tos.css';
import HeroBanner from './HeroBanner';
import backgroundImage from '../images/tos-bg.png';
import EJElogo from './eje-logo.png';

const Tos = () => {
  return (
    <>
    <HeroBanner
      backgroundImage={backgroundImage}
      logoImage={EJElogo}
      title="TOS"
    />
    <div className="o-container tos-dts">
        <div className="o-row">
            <div className="grid-row title-text">
              <p className="t-display-0 is-serif">Preamble</p>
            </div>
          </div>
          <div className="o-row">
            <div className="grid-row title-desc">
              <p className="t-body-2">The use of the official website of Emines Junior Entreprise &#40;hereinafter the "Website"	&#41; implies full acceptance of these general terms of access and use of the Website &#40;hereinafter the "Terms of Use"	&#41; by the user &#40;hereinafter the "User" or the "Users"	&#41;.</p>
              <p className="t-body-2">The term "Use" refers equally to any consultation, search, or download of elements from the Website. The terms "Editor," "EJE," or "We" are used to designate Emines Junior Entreprise and its departments.
      </p>
            </div>
          </div>
          <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Updates</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">EJE reserves the right to modify and update the Website as well as the Terms of Use at any time. Any modification to the Terms of Use will be issued with a dated and referenced version. The new version can be consulted on the Website. In this case, the date of the last update indicated on the Website will be modified.
</p>
          <p className="t-body-2">Each modification takes immediate effect upon publication on the Website. If the User continues to use the Website after the publication of modifications, this constitutes acceptance of these modifications. These modifications and updates are binding on the User, who must therefore regularly refer to this section to verify the current version.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Applications</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">EJE considers recruitment as the first step in an integration process. Each position for which EJE recruits will involve specific selection steps, with the aim always being to identify the right match between a person and a position. The steps of the recruitment process will be defined and organized in a dedicated section/tab &#40;under construction on the Website&#41;.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Pricing</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Access to the Website is free, excluding costs from internet service providers and telephone communication charges, which are billed directly by the telephone operators.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Limitations of Liability</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">The information, presentations, documents, and other content &#40;hereinafter the "Information"&#41; provided on the Website are offered in good faith, for informational purposes only, and without any guarantee, including potential errors or omissions. Therefore, EJE cannot be held responsible for them. Information may be modified or updated by EJE without notice.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">The User uses the Website at their sole responsibility. The User is deemed to have accepted the legal notices of the Website as well as these Terms of Use by simply consulting the Website. Under no circumstances shall EJE or its departments be held liable for the completeness, inaccuracy, or omission of this Information, nor for any direct and/or indirect damages, regardless of cause, origin, nature, or consequences, including incidental or special damages resulting from the content or Use of the Website and/or any of the links and addresses associated with it &#40;including access or inability to access the Website or any of these links, as well as any use made of this Information&#41;. This includes, without limitation, any loss of business, financial or commercial loss, loss of programs and/or data, particularly in the User's information system.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">The User assumes full responsibility for the risks associated with the importance and credit given to the Information. The Information is provided on the condition that the User or any other person receiving it can determine its relevance for a specific objective before using it. Under no circumstances shall EJE be held responsible for damages that may result from the importance given to the Information, its use for private or professional purposes, or its contradiction with any intellectual property rights such as patents or copyrights. EJE disclaims any liability, explicit or implied, if the use of the Information violates any intellectual property right. Furthermore, EJE rejects any interpretation that would liken the content of the Website to purchase offers or solicitations to acquire shares or other securities of EJE or its departments.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">No express or implied warranty is provided concerning the merchantability of the Information or its suitability for a particular purpose, nor regarding the products or services referred to in this Information. EJE is not obligated to update or correct the Information posted on the Website.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Regarding simulations that may be carried out on the Website, EJE reminds Users that these have no legal value. These simulations are based on the information provided by the Users in the relevant modules and are purely indicative.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">EJE does not guarantee that the Website will operate without interruption or that the servers providing access to it and/or third-party sites linked by hyperlinks do not contain viruses. Moreover, EJE shall not be liable for any data loss or damage suffered by the User.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Cookies</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Users are informed that, during their visits to the Website, a navigation tracking mechanism may be implemented. They have the right to access, remove, and modify personal data communicated through tracking techniques. When Users visit the Website, a session cookie is created, which records information about their computer’s browsing activities on the Website &#40;pages viewed, date and time of consultation, etc.&#41;, which EJE may access during future visits.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">During each visit, the pages viewed by the User create a session cookie that is downloaded to the User’s computer. Cookies are small text files that allow a server to recognize a computer and determine whether the computer &#40;and probably the User&#41; has visited the Website before. If the computer reconnects to the Website, it will search for and use these cookies.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Cookies do not identify Users themselves but only the computer being used. Cookies only record the sections of the Website that the computer in question has visited and the time spent there. Users are free to prevent the storage of cookies by configuring their browser accordingly. However, by rejecting cookies, Users may not receive certain personalized services, and this could slow down or prevent access to some parts of the Website.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">The User acknowledges being informed of this practice and authorizes EJE to use it. Users can disable cookies through the settings in their browser.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Intellectual Property</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">EJE holds all domain names related to "EJE" and its departments. The Website, as well as its components, constitute intellectual property protected by applicable laws. All data, texts, software, images, graphics, logos, sounds, videos, and other materials on the Website are the exclusive property of EJE. Any reproduction, representation, or distribution, in whole or in part, of the content or any extraction of the databases on the Website is prohibited. Any unauthorized use constitutes an infringement, liable to civil and criminal penalties.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Personal Data Protection</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Personal data refers to any information provided by Users, such as their names, email addresses, or passwords, that could directly or indirectly identify them, whether voluntarily submitted by them or collected while navigating the Website.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Personal data collected by EJE is exclusively intended for EJE's Communication Department to allow the User to benefit from the services provided by the Website.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Users have the right to access, modify, rectify, and delete their personal data at any time by contacting EJE through the "Contact" section of the Website or by sending a letter to the following address:
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">By postal mail addressed to:<br/>
Emines Junior Entreprise<br/>
Lot 660, Hay Moulay Rachid<br/>
Ben Guerir, 43150, Morocco
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Email: eje@emines.um6p.ma
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Hyperlinks</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">EJE disclaims any responsibility for the content of websites linked to the Website. These links are provided to Users as a complementary service. The decision to activate these links is entirely up to the User.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">EJE does not have full control over the content of these external websites and, therefore, cannot be held responsible for their accuracy, legality, or any data collection or use of cookies by these sites.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">The confidentiality and integrity of Information on the internet are not guaranteed. Consequently, any message sent by the User via email might be intercepted or altered, and EJE declines all responsibility.
</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Tos;