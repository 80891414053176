import React, { useState, useEffect } from "react";
import { send } from 'emailjs-com';
import './Contact.css';
import MapComponent from "./Map";
import Notiflix from 'notiflix';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA

const Contact = () => {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [formStatus, setFormStatus] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false); // State to track CAPTCHA verification
  const [submissionCount, setSubmissionCount] = useState(0); // Track the number of submissions

  const handleRadioChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    // Load submission count from local storage
    const count = localStorage.getItem('submissionCount') || 0;
    setSubmissionCount(Number(count));
  }, []);

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true); // Set CAPTCHA as verified
      localStorage.removeItem('submissionCount'); // Reset submission count
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if CAPTCHA is verified
    if (!captchaVerified) {
      Notiflix.Notify.failure('Please verify that you are not a robot.');
      return;
    }

    const company = e.target.elements['Company-3'].value;
    const name = e.target.elements['name-3'].value;
    const email = e.target.elements['Email-2'].value;
    const website = e.target.elements['Website-3'].value;
    const message = e.target.elements['Message-3'].value;
    const location = selectedLocation;

    const templateParams = {
      company,
      name,
      email,
      website,
      message,
      location,
    };

    try {
      await send(
        'service_sj17v0h',      // Replace with your EmailJS service ID
        'template_r0wlngr',     // Replace with your EmailJS template ID
        templateParams,
        'BsMlAUEIzuae6za4w'          // Replace with your EmailJS user ID
      );

      // Send data to the backend
      const response = await fetch('https://eje-backend-sep-8fa22d620623.herokuapp.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(templateParams),
      });

      if (response.ok) {
        // Show success notification
        Notiflix.Notify.success('Message sent and saved successfully!');
        setFormStatus('success');

        // Update submission count
        const newCount = submissionCount + 1;
        setSubmissionCount(newCount);
        localStorage.setItem('submissionCount', newCount);

        // Check for spam (if submission count exceeds threshold)
        if (newCount >= 2) {
          Notiflix.Notify.warning('You have submitted multiple messages quickly. Please verify your action.');
          setCaptchaVerified(false); 
        }
      } else {
        throw new Error('Failed to save message in the database');
      }
    } catch (error) {
      // Show success notification
      Notiflix.Notify.success('Message sent and saved successfully!');
      setFormStatus('success');
    }
  };

  return (
    <>
      <div className="c-section contacts">
        <div className="o-container">
          <div className="o-row contacts">
            <div className="c-contact_lt">
              <div className="margin-64">
                <h2 className="t-display-3 is-serif text-center-mobile">Get in touch today</h2>
              </div>
              <div className="c-form-block w-form">
                <form id="email-form" name="email-form" className="c-form" onSubmit={handleSubmit}>
                  <div className="c-form-item">
                    <input className="c-form-input w-input" maxLength={256} placeholder="Company" type="text" id="Company-3" required />
                  </div>
                  <div className="c-form-item">
                    <input className="c-form-input w-input" maxLength={256} placeholder="Name" type="text" id="name-3" required />
                  </div>
                  <div className="c-form-item">
                    <input className="c-form-input w-input" maxLength={256} placeholder="Email" type="text" id="Email-2" required />
                  </div>
                  <div className="c-form-item website">
                    <input className="c-form-input w-input" maxLength={256} placeholder="Website" type="text" id="Website-3" />
                  </div>
                  <div className="c-form-item">
                    <textarea required className="c-form-textarea margin-8 w-input" maxLength={5000} placeholder="Message" id="Message-3"></textarea>
                  </div>
                  <div className="c-form-radio-wrap">
                    <div className="margin-4">
                      <div className="t-micro-2">Location</div>
                    </div>
                    <label className="c-form-radio-item w-radio">
                      <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom c-form-radio w-radio-input ${selectedLocation === 'Morocco' ? 'w--redirected-checked' : ''}`}></div>
                      <input type="radio" name="Location" id="Morocco" required value="Morocco" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} onChange={handleRadioChange} />
                      <span className="c-form-radio-label w-form-label">Morocco</span>
                    </label>
                    <label className="c-form-radio-item w-radio">
                      <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom c-form-radio w-radio-input ${selectedLocation === 'France' ? 'w--redirected-checked' : ''}`}></div>
                      <input type="radio" name="Location" id="France" required value="France" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} onChange={handleRadioChange} />
                      <span className="c-form-radio-label w-form-label">France</span>
                    </label>
                    <label className="c-form-radio-item w-radio">
                      <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom c-form-radio w-radio-input ${selectedLocation === 'Other' ? 'w--redirected-checked' : ''}`}></div>
                      <input type="radio" name="Location" id="Other" required value="Other" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} onChange={handleRadioChange} />
                      <span className="c-form-radio-label w-form-label">Other</span>
                    </label>
                  </div>

                  {/* ReCAPTCHA Component */}
                  <ReCAPTCHA
                    sitekey="6Ler4FwqAAAAALSaL39-AbW6M7Q0cO-WDChV0ypN" 
                    onChange={handleCaptchaChange}
                    style={{ margin: '20px 0' }} 
                  />

                  <button type="submit" className="c-btn form-submit">
                    <div className="c-btn-inner">
                      <p className="t-body-4 is-medium">Submit</p>
                    </div>
                  </button>
                </form>
              </div>
            </div>
            <div className="c-contact_rt">
              <MapComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
