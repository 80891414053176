import React, { useEffect, useRef } from 'react';

const SectionObserver = ({ children, onIntersect }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (!sectionRef.current) return;
      
      const rect = sectionRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        onIntersect();
      }
    };

    window.addEventListener('scroll', checkScrollPosition);
    window.addEventListener('resize', checkScrollPosition); // to handle window resize as well

    // Initial check
    checkScrollPosition();

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
      window.removeEventListener('resize', checkScrollPosition);
    };
  }, [onIntersect]);

  return <div ref={sectionRef}>{children}</div>;
};

export default SectionObserver;
