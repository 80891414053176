import React from "react";
import './hero-services.css';
import RibbonVid from '../videos/flowing-ribbon.mp4'

const HeroServices = () => {
  return (
    <div className="c-section portfolio-hero">
      <video autoPlay muted className="video-background">
        <source src={RibbonVid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="o-container">
        <div className="o-row vertical">
          <div className="o-col _w-14 md-w-20 sm-w-24">
            <h1 className="t-display-1 is-serif">
              Unleashing versatility, <br/>empowering talented students to shine in every field
            </h1>
          </div>
          <p className="t-body-2">
            <br/>
            At our core, we believe in the power of specialization and innovation. That's why <br/>
            our enterprise is strategically divided into three dynamic departments: Business,<br/>
            Digital, and Analytics. Each department is a hub of excellence, driven by talented<br/>
            students who are eager to apply their unique skills and insights to real-world <br/>
            challenges.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroServices;
