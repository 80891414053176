import React, { useState, useEffect } from "react";
import './BusinessDetails.css';

const BusinessDetails = () => {
  const [modalState, setModalState] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
  });

  // Function to open a specific modal
  const openModal = (modalKey) => {
    setModalState({
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      [modalKey]: true, // Only the clicked modal is true (open)
    });
  };

  // Function to close all modals
  const closeModal = () => {
    setModalState({
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
    });
  };

   // Effect to disable scrolling on the background when a modal is open
useEffect(() => {
  const isAnyModalOpen = Object.values(modalState).some((isOpen) => isOpen);
  
  if (isAnyModalOpen) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }

  // Cleanup on unmount or when modal is closed
  return () => document.body.classList.remove('no-scroll');
}, [modalState]);
  return (
    <>
    <div className="o-container business-dts services">
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Business Consulting & Strategy</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">Emines Junior Entreprise offers comprehensive business consulting services designed to elevate your strategy and operations. Our team of expert consultants works closely with clients to understand their unique challenges and opportunities. We provide tailored solutions in areas such as market analysis, competitive strategy, and business development, ensuring that our clients stay ahead in their industries.</p>
          <p className="t-body-2">Whether you're looking to refine your business model, explore new markets, or optimize your operations, we have the expertise to guide you through every step of the process. Our goal is to empower businesses with the knowledge and tools they need to succeed.</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-section">
          <h1 className="title-display-1">Our Consulting Services for Business Related Projects</h1>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row services-grid o-grid grid-columns-2">
        <a className="c-card c-block-item w-inline-block" role="group" aria-label="1/6" onClick={() => openModal('modal5')}>
            <div className="c-icon small w-embed">
            <svg fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 415.881 415.881" xmlSpace="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M179.641,189.565c2.455,0,4.869,0.193,7.223,0.561l36.999-36.998c-13.193-7.048-28.249-11.051-44.221-11.051 c-51.92,0-94.162,42.241-94.162,94.162c0,51.921,42.242,94.162,94.162,94.162s94.161-42.241,94.161-94.162 c0-15.973-4.002-31.027-11.051-44.22l-36.997,36.999c0.367,2.354,0.56,4.766,0.56,7.222c0,25.736-20.937,46.674-46.672,46.674 c-25.736,0-46.674-20.938-46.674-46.674S153.905,189.565,179.641,189.565z"></path> <path d="M290.454,164.316c13.488,20.712,21.338,45.417,21.338,71.922c0,72.87-59.281,132.153-132.15,132.153 c-72.869,0-132.153-59.283-132.153-132.152s59.283-132.153,132.152-132.153c26.508,0,51.211,7.851,71.924,21.34l34.104-34.104 c-29.738-21.817-66.402-34.724-106.027-34.724c-99.055,0-179.641,80.587-179.641,179.641c0,99.054,80.586,179.642,179.641,179.642 c99.054,0,179.638-80.588,179.638-179.642c0-39.626-12.904-76.29-34.721-106.026L290.454,164.316z"></path> <path d="M415.415,56.64c-1.119-3.539-4.119-6.157-7.775-6.793l-35.449-6.157l-6.156-35.45c-0.637-3.656-3.256-6.655-6.793-7.774 c-3.537-1.122-7.402-0.178-10.027,2.447l-27.412,27.411c-1.863,1.864-2.91,4.393-2.912,7.029l0.002,40.896l-148.1,148.096 c-5.176,5.177-5.176,13.566,0,18.743c5.178,5.175,13.568,5.177,18.744,0L337.632,96.991h40.896c2.635,0,5.164-1.047,7.027-2.911 l27.412-27.413C415.593,64.044,416.536,60.177,415.415,56.64z"></path> </g> </g> </g></svg>
            </div>
            <h3 className="t-body-1">Benchmarking and Competitive Intelligence</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="2/6" onClick={() => openModal('modal1')}>
            <div className="c-icon small w-embed">
              <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Combined-Shape" fill="currentColor" transform="translate(64.000000, 64.000000)">
                        <path d="M42.6666667,1.42108547e-14 L42.4939709,343.395437 L384,341.333333 L384,384 L1.42108547e-14,384 L0.172695782,1.42108547e-14 L42.6666667,1.42108547e-14 Z M384,64 L384,192 L341.333333,192 L341.333055,135.367 L233.893601,232.06349 L181.333333,179.52 L79.0849447,281.751611 L48.9150553,251.581722 L181.333333,119.163444 L235.434667,173.248 L309.438055,106.666 L256,106.666667 L256,64 L384,64 Z">
               </path>
                    </g>
                </g>
              </svg>
            </div>
            <h3 className="t-body-1">Market Research</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="3/6" onClick={() => openModal('modal2')}>
          <div className="c-icon small w-embed">
          <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="currentColor"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd"> <g id="add" fill="currentColor" transform="translate(42.666667, 64.000000)"> <path d="M266.666667,128 C331.468077,128 384,180.531923 384,245.333333 C384,270.026519 376.372036,292.938098 363.343919,311.840261 L423.228475,371.725253 L393.058586,401.895142 L333.173594,342.010585 C314.271431,355.038703 291.359852,362.666667 266.666667,362.666667 C201.865256,362.666667 149.333333,310.134744 149.333333,245.333333 C149.333333,180.531923 201.865256,128 266.666667,128 Z M266.666667,170.666667 C225.429405,170.666667 192,204.096072 192,245.333333 C192,286.570595 225.429405,320 266.666667,320 C307.903928,320 341.333333,286.570595 341.333333,245.333333 C341.333333,204.096072 307.903928,170.666667 266.666667,170.666667 Z M128.404239,234.665576 C128.136379,238.186376 128,241.743928 128,245.333333 C128,256.34762 129.284152,267.061976 131.710904,277.334851 L7.10542736e-15,277.333333 L7.10542736e-15,234.666667 L128.404239,234.665576 Z M85.3333333,1.42108547e-14 L85.3333333,213.333333 L21.3333333,213.333333 L21.3333333,1.42108547e-14 L85.3333333,1.42108547e-14 Z M170.666667,85.3333333 L170.663947,145.273483 C151.733734,163.440814 137.948238,186.928074 131.710904,213.331815 L106.666667,213.333333 L106.666667,85.3333333 L170.666667,85.3333333 Z M256,42.6666667 L255.999596,107.070854 C232.554315,108.854436 210.738728,116.46829 191.999452,128.465799 L192,42.6666667 L256,42.6666667 Z M341.333333,64 L341.333983,128.465865 C322.594868,116.468435 300.779487,108.854588 277.334424,107.070906 L277.333333,64 L341.333333,64 Z" id="Combined-Shape"> </path> </g> </g> </g></svg>
            </div>
            <h3 className="t-body-1">Technology watch and state of the art</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="4/6" onClick={() => openModal('modal3')}>
          <div className="c-icon small w-embed">
            <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="currentColor"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g ><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="icon" fill="currentColor" transform="translate(64.000000, 64.000000)"> <path d="M295.791,246.875333 L350.457,301.542333 L246.666667,405.333333 L192,405.333333 L192,350.666667 L295.791,246.875333 Z M85.3333333,1.42108547e-14 L85.3333333,192 L213.333333,122.666667 L213.333333,192 L341.333333,121.8816 L341.333,171.163 L170.666667,341.830101 L170.666,384 L1.42108547e-14,384 L1.42108547e-14,1.42108547e-14 L85.3333333,1.42108547e-14 Z M345.333333,197.333333 L400,252 L365.542,286.457333 L310.876,231.790333 L345.333333,197.333333 Z" id="Combined-Shape"> </path> </g> </g> </g></svg>
            </div>
            <h3 className="t-body-1">Techno-economic evaluation</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="5/6" onClick={() => openModal('modal4')}>
            <div className="c-icon small w-embed">
            <svg fill="currentColor" width="52" height="52" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m43.76 42a1.53 1.53 0 0 1 1.44 1.6v1.6a1.53 1.53 0 0 1 -1.44 1.6h-40.32a1.53 1.53 0 0 1 -1.44-1.6v-1.6a1.53 1.53 0 0 1 1.44-1.6zm-36.76-28.8a1.45 1.45 0 0 1 1.4 1.46v22.68a1.45 1.45 0 0 1 -1.4 1.46h-3.58a1.45 1.45 0 0 1 -1.42-1.46v-22.68a1.45 1.45 0 0 1 1.42-1.46zm19 0a1.54 1.54 0 0 1 1.6 1.46v22.68a1.54 1.54 0 0 1 -1.6 1.46h-4.8a1.54 1.54 0 0 1 -1.6-1.46v-22.68a1.54 1.54 0 0 1 1.6-1.46zm-9.6 0a1.54 1.54 0 0 1 1.6 1.46v22.68a1.54 1.54 0 0 1 -1.6 1.46h-1.6a1.54 1.54 0 0 1 -1.6-1.46v-22.68a1.54 1.54 0 0 1 1.6-1.46zm22.9 0a1.13 1.13 0 0 1 .86.42l9.47 10.65a1.44 1.44 0 0 1 .37 1 1.41 1.41 0 0 1 -.37 1l-9.43 10.51a1.12 1.12 0 0 1 -.86.42 1.1 1.1 0 0 1 -.86-.42l-1.72-1.93a1.44 1.44 0 0 1 -.37-1 1.41 1.41 0 0 1 .37-1l3-3.34a1 1 0 0 0 .17-1 .82.82 0 0 0 -.75-.57h-7a1.5 1.5 0 0 1 -1.31-1.4v-2.73a1.41 1.41 0 0 1 1.31-1.39h7a.84.84 0 0 0 .75-.57 1 1 0 0 0 -.17-1l-3-3.33a1.45 1.45 0 0 1 -.36-1 1.48 1.48 0 0 1 .36-1l1.73-1.94a1.1 1.1 0 0 1 .81-.38zm4.46-8a1.53 1.53 0 0 1 1.44 1.6v1.6a1.53 1.53 0 0 1 -1.44 1.6h-40.32a1.53 1.53 0 0 1 -1.44-1.6v-1.6a1.53 1.53 0 0 1 1.44-1.6z"></path></g></svg>
            </div>
            <h3 className="t-body-1">Supply chain risk & opportunity assessment </h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          
          <div className="c-line tech-hoz" id="w-node-_49a7040a-af5d-67e0-3541-5cc642a706c8-c73c5655"></div>
          <div className="c-line tech-hoz-2 hide-tablet" id="w-node-_49a7040a-af5d-67e0-3541-5cc642a706c8-c73c5655"></div>
          <div className="c-line tech-ver-1" id="w-node-b3d8c949-042c-c076-7d24-21e042bea05b-c73c5655"></div>
        </div>
      </div>
      
    </div>
    <div className={`c-service-modal business-dts ${modalState.modal5 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Benchmarking and Competitive Intelligence</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>To ensure the viability of your company, products, and services, EJE – Business offers you an in-depth analysis of your direct and indirect competitors, accompanied by a concrete summary and a tailored action plan.</p>
            <p>Thanks to the engineer-manager training provided at EMINES and the Alumni network, our Junior Consultants conduct an analysis covering technical, operational, financial, and strategic dimensions, while also incorporating the study of potential technological disruptions.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal5 ? 'open' : ''}`}></div>
    <div className={`c-service-modal business-dts ${modalState.modal1 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Market Research</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>The EJE-B team delivers a quantitative and qualitative market analysis in terms of size and trends, shedding light on growth segments and the specific features of your competitors. Our analysis also covers ease of penetration, regulations governing your target market, and estimates of typical margins.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal1 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal2 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Technology watch and state of the art</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>To help you grow your business, EJE - Business offers you a comprehensive benchmark of the latest and most reliable technologies in your sector. Accompanied by an in-depth, customized analysis for your company, our benchmarks will enable you to better define avenues for improvement and fuel your competitive advantages.</p>
            <p>In addition, EJE-Business offers large companies a state-of-the-art review of the technologies critical to your fields of activity, covering research papers, patents and pilot projects. Accompanied by an in-depth analysis of the level of maturity, our study will enable you to apprehend the risks, but above all the associated opportunities.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal2 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal3 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Techno-economic evaluation </h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>Do you have ideas in mind that could grow your business? Are you demotivated by fear and uncertainty? Then you've come to the right place.</p>
            <p>EJE-Business can help you structure and transform your ideas into project proposals, and assess the technical feasibility and economic viability of each one. Combining engineering and economics, our junior consultants measure the associated risks and opportunities, synthesize the study clearly and provide you with a detailed report.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal3 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal4 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Supply chain risk & opportunity assessment  </h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>Too focused on managing production and sales, your company might overlook the analysis of risks—and, importantly, the opportunities—related to supply and distribution.</p>
            <p>EJE-Business conducts a comprehensive analysis of your current supply chain by mapping the value chain behind your raw materials and suppliers, allowing you to identify price factors, disruption risks, and regulatory impacts.</p>
            <p>Similarly, our analysis of your distribution chain, right up to the end customer, highlights risks (such as logistical disruptions and private-label launches) and opportunities (like cost and time reductions) that affect your business.</p>
            <p>With our team’s recommendations, you can anticipate these risks and seize potential opportunities, adopting a proactive approach.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal4 ? 'open' : ''}`}></div>
    </>
  )
}

export default BusinessDetails;