import React from "react";
import HeroBanner from './HeroBanner';
import backgroundImage from '../images/analytics-bg.webp';
import logoImage from '../images/eje-analytics-logo.png';

const AnalyticsHero = () => {
  return (
    <>
    <HeroBanner
      backgroundImage={backgroundImage}
      logoImage={logoImage}
      title="Analytics"
    />
    </>
  )
}

export default AnalyticsHero;