import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import SectionObserver from './SectionObserver';
import Values from './Values';

const Numbers = () => {
  const [trigger, setTrigger] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [clientSatisfaction, setClientSatisfaction] = useState(0);
  const [juniorConsultants, setJuniorConsultants] = useState(0);
  const [salesFigures, setSalesFigures] = useState(0);
  const [awards, setAwards] = useState(0);

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
}, []);

  const handleIntersect = () => {
    setTrigger(true);
  };

  useEffect(() => {
    if (trigger) {
      const incrementClientSatisfaction = setInterval(() => {
        setClientSatisfaction((prev) => {
          if (prev < 95) return prev + 1;
          clearInterval(incrementClientSatisfaction);
          return 95;
        });
      }, 50);

      const incrementJuniorConsultants = setInterval(() => {
        setJuniorConsultants((prev) => {
          if (prev < 100) return prev + 2;
          clearInterval(incrementJuniorConsultants);
          return 100;
        });
      }, 100);

      const incrementSalesFigures = setInterval(() => {
        setSalesFigures((prev) => {
          if (prev < 20) return prev + 1;
          clearInterval(incrementSalesFigures);
          return 20;
        });
      }, 250);

      const incrementAwards = setInterval(() => {
        setAwards((prev) => {
          if (prev < 5) return prev + 1;
          clearInterval(incrementAwards);
          return 5;
        });
      }, 500);
    }
  }, [trigger]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: windowWidth >= 991 ? 7 : 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false
  };

  return (
    <>
      <section className='company-fun-facts section' id='get-to-know-us'>
        <div className='o-container'>
          <div className='row top'>
            <div className='col-12 text-center'>
              <h2>
                <span className='t-display-2 is-serif'>
                We Strive For Excellence
                </span>
                
                {/*<SectionObserver onIntersect={handleIntersect}>
                  <span className={`t-display-2 is-serif ${trigger ? 'show' : 'hide'}`}>
                  {[
                  'We',
                  'Strive',
                  'For',
                  'Excellence'
                ].map((word, wordIndex, wordArray) => (
                  <div
                    className={`word ${word === 'your' ? 'highlight-word' : ''}`}
                    key={wordIndex}
                  >
                    {word.split('').map((char, charIndex) => (
                      <div
                        className={`char`}
                        key={charIndex}
                        style={{ '--char-index': wordIndex * 10 + charIndex }}
                      >
                        {char}
                      </div>
                    ))}
                    &nbsp;
                  </div>
                ))}
                  </span>
                </SectionObserver>*/}
              </h2>
              <SectionObserver onIntersect={handleIntersect}>
                </SectionObserver>
              <span className='t-micro-1'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Key numbers</span>
            </div>
          </div>

          <div className='row middle'>

            <div className='col-lg-3 col-md-6'>
              <div className='fun-fact text-center'>
                <i className="fa fa-smile-o fa-2x"></i>
                <h3>{clientSatisfaction}%</h3>
                <p>Client Satisfaction</p>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='fun-fact text-center'>
                <i className='fa fa-person fa-2x'></i>
                <h3>+{juniorConsultants}</h3>
                <p>Junior Consultants</p>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='fun-fact text-center'>
                <i className='fa fa-sack-dollar fa-2x'></i>
                <h3>+{salesFigures} kUSD</h3>
                <p>Sales Figures</p>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='fun-fact text-center'>
                <i className='fa fa-award fa-2x'></i>
                <h3>{awards}</h3>
                <p>Winning Junior Entreprise Awards</p>
              </div>
            </div>
          </div>
        </div>
        <Values/>
      </section>
    </>
  );
};

export default Numbers;
