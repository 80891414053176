import React, { useEffect, useRef } from 'react';
import './Ribbons.css';

const Ribbons = () => {
  const holderRef = useRef(null);
  const wavesRef = useRef(null); // Reference to store the Waves instance

  useEffect(() => {
    const pi = Math.PI;
    const pi2 = 2 * Math.PI;

    class Stats {
      constructor() {
        this.data = [];
      }

      time() {
        return (performance || Date).now();
      }

      log() {
        if (!this.last) {
          this.last = this.time();
          return 0;
        }

        this.new = this.time();
        this.delta = this.new - this.last;
        this.last = this.new;

        this.data.push(this.delta);
        if (this.data.length > 10) this.data.shift();
      }

      fps() {
        let fps = 0;
        this.data.forEach((data) => {
          fps += data;
        });
        return Math.round(1000 / (fps / this.data.length));
      }
    }

    function each(items, callback) {
      for (let i = 0; i < items.length; i++) {
        callback(items[i], i);
      }
    }

    function extend(options, defaults) {
      for (let key in options) if (defaults.hasOwnProperty(key)) defaults[key] = options[key];
      return defaults;
    }

    function dtr(deg) {
      return (deg * pi) / 180;
    }

    function rnd(a, b) {
      if (arguments.length === 1) return Math.random() * a;
      return a + Math.random() * (b - a);
    }

    function rnd_sign() {
      return Math.random() > 0.5 ? 1 : -1;
    }

    class Wave {
      constructor(Waves) {
        this.Waves = Waves;
        this.Lines = [];
        const speed = Waves.options.speed;

        this.angle = [rnd(pi2), rnd(pi2), rnd(pi2), rnd(pi2)];

        this.speed = [
          rnd(speed[0], speed[1]) * rnd_sign(),
          rnd(speed[0], speed[1]) * rnd_sign(),
          rnd(speed[0], speed[1]) * rnd_sign(),
          rnd(speed[0], speed[1]) * rnd_sign(),
        ];
      }

      update() {
        this.Lines.push(new Line(this, this.Waves.color));
        if (this.Lines.length > this.Waves.options.width) {
          this.Lines.shift();
        }
      }

      draw() {
        const ctx = this.Waves.ctx;
        const radius = this.Waves.radius;
        const radius3 = radius / 3;
        const x = this.Waves.centerX;
        const y = this.Waves.centerY;
        const rotation = dtr(this.Waves.options.rotation);
        const amplitude = this.Waves.options.amplitude;
        const debug = this.Waves.options.debug;

        this.Lines.forEach((line, i) => {
          if (debug && i > 0) return;

          const angle = line.angle;

          const x1 = x - radius * Math.cos(angle[0] * amplitude + rotation);
          const y1 = y - radius * Math.sin(angle[0] * amplitude + rotation);
          const x2 = x + radius * Math.cos(angle[3] * amplitude + rotation);
          const y2 = y + radius * Math.sin(angle[3] * amplitude + rotation);
          const cpx1 = x - radius3 * Math.cos(angle[1] * amplitude * 2);
          const cpy1 = y - radius3 * Math.sin(angle[1] * amplitude * 2);
          const cpx2 = x + radius3 * Math.cos(angle[2] * amplitude * 2);
          const cpy2 = y + radius3 * Math.sin(angle[2] * amplitude * 2);

          ctx.strokeStyle = debug ? '#fff' : line.color;

          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, x2, y2);
          ctx.stroke();

          if (debug) {
            ctx.strokeStyle = '#fff';
            ctx.globalAlpha = 0.3;

            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(cpx1, cpy1);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(x2, y2);
            ctx.lineTo(cpx2, cpy2);
            ctx.stroke();

            ctx.globalAlpha = 1;
          }
        });
      }
    }

    class Line {
      constructor(Wave, color) {
        const angle = Wave.angle;
        const speed = Wave.speed;

        this.angle = [
          Math.sin((angle[0] += speed[0])),
          Math.sin((angle[1] += speed[1])),
          Math.sin((angle[2] += speed[2])),
          Math.sin((angle[3] += speed[3])),
        ];

        this.color = color;
      }
    }

    class Waves {
      constructor(holder, options) {
        this.options = extend(options || {}, {
          resize: false,
          rotation: 45,
          waves: 5,
          width: 100,
          hue: [12, 16],
          amplitude: 0.5,
          background: false,
          preload: false,
          speed: [0.004, 0.008],
          debug: false,
          fps: false,
          duration: 5000, // Duration in milliseconds to run the animation
        });

        this.waves = [];

        this.holder = holder;
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.holder.appendChild(this.canvas);

        this.hue = this.options.hue[0];
        this.hueFw = true;
        this.stats = new Stats();

        this.resize();
        this.init(this.options.preload);

        if (this.options.resize) {
          window.addEventListener(
            'resize',
            () => {
              this.resize();
            },
            false
          );
        }
      }

      init(preload) {
        for (let i = 0; i < this.options.waves; i++) this.waves[i] = new Wave(this);

        if (preload) this.preload();
      }

      preload() {
        for (let i = 0; i < this.options.waves; i++) {
          this.updateColor();
          for (let j = 0; j < this.options.width; j++) {
            this.waves[i].update();
          }
        }
      }

      render() {
        this.updateColor();
        this.clear();

        if (this.options.debug) {
          this.ctx.beginPath();
          this.ctx.strokeStyle = '#f00';
          this.ctx.arc(this.centerX, this.centerY, this.radius, 0, pi2);
          this.ctx.stroke();
        }

        if (this.options.background) {
          this.background();
        }

        this.waves.forEach((wave) => {
          wave.update();
          wave.draw();
        });
      }

      animate() {
        this.render();

        if (this.options.fps) {
          this.stats.log();
          this.ctx.font = '12px Arial';
          this.ctx.fillStyle = '#fff';
          this.ctx.fillText(this.stats.fps() + ' FPS', 10, 22);
        }

        if (this.animationActive) {
          window.requestAnimationFrame(this.animate.bind(this));
        }
      }

      startAnimation() {
        this.animationActive = true;
        this.animate();
      }

      stopAnimation() {
        this.animationActive = false;
      }

      clear() {
        this.ctx.clearRect(0, 0, this.width, this.height);
      }

      background() {
        const gradient = this.ctx.createLinearGradient(0, 0, 0, this.height);
        gradient.addColorStop(0, '#fff');
        gradient.addColorStop(1, '#fff');

        this.ctx.fillStyle = gradient;
        this.ctx.fillRect(0, 0, this.width, this.height);
      }

      resize() {
        const width = this.holder.offsetWidth;
        const height = this.holder.offsetHeight;
        this.scale = window.devicePixelRatio || 1;
        this.width = width * this.scale;
        this.height = height * this.scale;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.canvas.style.width = width + 'px';
        this.canvas.style.height = height + 'px';
        this.radius = Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2)) / 2;
        this.centerX = this.width / 2;
        this.centerY = this.height / 2;
      }

      updateColor() {
        this.hue += this.hueFw ? 0.01 : -0.01;

        if (this.hue > this.options.hue[1] && this.hueFw) {
          this.hue = this.options.hue[1];
          this.hueFw = false;
        } else if (this.hue < this.options.hue[0] && !this.hueFw) {
          this.hue = this.options.hue[0];
          this.hueFw = true;
        }

        const a = Math.floor(127 * Math.sin(0.3 * this.hue + 0) + 128);
        const b = Math.floor(127 * Math.sin(0.3 * this.hue + 2) + 128);
        const c = Math.floor(127 * Math.sin(0.3 * this.hue + 4) + 128);

        this.color = `rgba(${a},${b},${c}, 0.1)`;
      }
    }

    const holder = holderRef.current;
    const createWaves = () => {
      if (wavesRef.current) {
        wavesRef.current.stopAnimation();
        holder.removeChild(wavesRef.current.canvas);
      }

      const waves = new Waves(holder, {
        fps: false,
        waves: 3,
        width: 200,
        duration: 5000, // Duration in milliseconds to run the animation
      });

      waves.startAnimation();
      wavesRef.current = waves;

      setTimeout(() => {
        waves.stopAnimation();
      }, 5000);
     
    };

    createWaves();

    const handleResize = () => {
      createWaves();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      
    };
  }, []);

  return <div id="holder" ref={holderRef}></div>;
};

export default Ribbons;
