import React from "react";  
import TeamImg from '../images/hero-slider-01.jpg';
import './Team.css';

const Team = () => {
  return (
    <>
      <div className="c-section hm-section-2 Team">
        <div className="o-container">
          <div className="o-row margin-64">
            <div className="o-col _w-16 sm-w-24">
              <div className="t-micro-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;team</div>
              <h2 className="t-display-2 is-serif">EJE,<br/>mandate 24/25</h2>   
            </div>
            <div className="o-col _w-8 md-w-14 sm-w-24">
                <div className="margin-32">
                  <p className="t-body-4">
                    Different profiles, different tastes and different work ethics. But our executive office is radiating with talent and works well together.
                  </p>
                </div>
                <a className="c-btn w-inline-block" href="/team">
                  <div className="btn-inner">
                    <p className="t-body-4 is-medium">The team</p>
                    <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
              </div>
          </div>
          <div className="c-img-contain">
              <img className="c-img" src={TeamImg} alt="team-img" ></img>
          </div>
        </div>
      </div>
    </>
  )
}

export default Team;