import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Partners.css';
import Partner1 from '../images/clients/agrisafe_logo-1.png';
import Partner2 from '../images/clients/emineslogo.png';
import Partner3 from '../images/clients/finovox_logo.png';
import Partner4 from '../images/clients/glutect_logo.png';
import Partner5 from '../images/clients/iresen.png';
import Partner6 from '../images/clients/logo dome home.png';
import Partner7 from '../images/clients/ocp-logo.svg';
import Partner8 from '../images/clients/pcl-logo.png';
import Partner9 from '../images/clients/solar-DA.png';
import Partner10 from '../images/clients/um6p_logo.png';

const Partners = () => {
  const logos1 = [
    { img: Partner1, link: "https://www.agrisafe.org/" },
    { img: Partner2, link: "https://www.emines-ingenieur.org/" },
    { img: Partner3, link: "https://www.finovox.com/" },
    { img: Partner4, link: "https://glutect.com/" },
    { img: Partner5, link: "https://iresen.org/" },
  ];

  const logos2 = [
    { img: Partner6, link: "https://www.arkegreen.com/" },
    { img: Partner7, link: "https://www.ocpgroup.ma/" },
    { img: Partner8, link: "#" },  // Placeholder link
    { img: Partner9, link: "https://www.solardecathlonafrica.com/" },
    { img: Partner10, link: "https://um6p.ma/" },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const settings = {
    infinite: true,
    arrows: false,
    speed: 5000,
    slidesToShow: windowWidth >= 991 ? 5 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  return (
    <div className="section_common_partners">
      <div className="padding-section-medium">
        <div className="container-large">
          <div className="common_partners_component">
            <div className="text-container">
              <span className="t-micro-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;clients</span>
              <h2 className="t-display-2 is-serif">Who We Work With</h2>
            </div>
            <div className="o-row vertical-center margin-80">
              <div className="o-col _w-10 md-w-16 sm-w-24">
                <p className="t-body-4 text-center">
                  At EJE, our clients and partners are at the heart of everything we do. Their trust in us fuels our mission to deliver outstanding solutions, time and time again.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {logos1.map((partner, index) => (
          <div key={index}>
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
              <img
                className="common_partners_item-image"
                loading="lazy"
                src={partner.img}
                alt={`Partner ${index + 1}`}
              />
            </a>
          </div>
        ))}
      </Slider>
      <Slider {...settings} rtl={true}>
        {logos2.map((partner, index) => (
          <div key={index}>
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
              <img
                className="common_partners_item-image"
                loading="lazy"
                src={partner.img}
                alt={`Partner ${index + 1}`}
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Partners;
