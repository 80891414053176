import React from 'react';
import '../components/styles.less';
import NavBar2 from '../components/Navbar/NavBar2';
import CHero from '../components/C-Hero';
import About from '../components/About';
import Numbers from '../components/Numbers';
import Team from '../components/Team';
import Events from '../components/Events';
import Partners from '../components/Partners';
import Testimonials from '../components/Testimants';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

import '../index.css';
const Home = () => {
    return (
            <div className='homepage'>
                
                <NavBar2 />
                <CHero />
                <About />
                <Numbers />
                <Team />
                <Events />
                <Partners />
                <Testimonials />
                <Contact />
                <Footer />
                
</div>

    )
}

export default Home;

