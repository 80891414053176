import React from 'react';
import './HeroBanner.css'; // Ensure this file contains the CSS provided

const HeroBanner = ({ backgroundImage, logoImage, title }) => {
  return (
    <div className="hero-banner" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content">
        <h1 className="t-display-1 is-serif">{title}</h1>
        <img src={logoImage} alt="Logo" className="logo" />
      </div>
    </div>
  );
};

export default HeroBanner;
