import React from 'react';
import NavBar2 from '../components/Navbar/NavBar2';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import '../index.css';

const ContactPage = () => {
    useDocTitle('Send us a message')
    
    return (
        <div className='contact-page'>  
          <NavBar2 />
          <Contact />
          <Footer />
        </div>


    )

}

export default ContactPage;