import React, { useState } from 'react';
import NavBar2 from '../components/Navbar/NavBar2';
import DigitalHero from '../components/DigitalHero';
import DigitalDetails from '../components/DigitalDetails';
import Footer from '../components/Footer';

import {useDocTitle} from '../components/CustomHook';


const Digital = () => {
    useDocTitle('EJE Digital')

    return (
        <div className='digital-page'>  
          <NavBar2 />
          <DigitalHero />
          <DigitalDetails />
          <Footer />
        </div>


    )
}

export default Digital;