import React from 'react';
import './Values.css'; 

const Values = () => {
  return (
    <div className="banner-container">
      <div className="banner-text">
        <span className="text-content">
        &nbsp;Excellence • Bienveillance • Intégrité • Excellence • Bienveillance • Intégrité •
        </span>
        <span className="text-content">
        &nbsp;Excellence • Bienveillance • Intégrité • Excellence • Bienveillance • Intégrité •
        </span>
      </div>
    </div>
  );
};

export default Values;
