import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Services from './pages/Services';
import Business from './pages/Business';
import Digital from './pages/Digital';
import Analytics from './pages/Analytics';
import Team from './pages/Team';
import ContactPage from './pages/Contact';
import TosPage from './pages/TosPage';
import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Emines Junior Entreprise");

   
  (function(d, s) {
    var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
    e.async = true; e.src = "//static.axept.io/sdk.js";
    t.parentNode.insertBefore(e, t);
  })(document, "script");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/business" element={<Business />} />
            <Route path="/services/digital" element={<Digital />} />
            <Route path="/services/analytics" element={<Analytics />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/tos" element={<TosPage />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
