import React, {useState, useEffect} from "react";
import './DigitalDetails.css';

const DigitalDetails = () => {
  const [modalState, setModalState] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
  });

  // Function to open a specific modal
  const openModal = (modalKey) => {
    setModalState({
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      [modalKey]: true, // Only the clicked modal is true (open)
    });
  };

  // Function to close all modals
  const closeModal = () => {
    setModalState({
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
    });
  };

    // Effect to disable scrolling when modal is open
    useEffect(() => {
      const isAnyModalOpen = Object.values(modalState).some((isOpen) => isOpen);
      
      if (isAnyModalOpen) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
      
      // Cleanup on unmount or when modal is closed
      return () => document.body.classList.remove('no-scroll');
    }, [modalState]);
  return (
    <>
    <div className="o-container digital-dts services">
      <div className="o-row">
        <div className="grid-row title-text">
          <p className="t-display-0 is-serif">Digital Solutions & Innovation</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-desc">
          <p className="t-body-2">In today’s digital world, your online presence is crucial to success. At EJE, we specialize in transforming ideas into innovative digital solutions. Our services range from website development and mobile app creation to digital marketing strategies. We focus on delivering high-quality, user-friendly digital experiences that resonate with your target audience and drive business growth.</p>
          <p className="t-body-2">Our team of tech-savvy experts stays on top of the latest digital trends, ensuring that your business is equipped with cutting-edge tools and technologies. Let us help you make a lasting impact in the digital space.
</p>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row title-section">
          <h1 className="title-display-1">Our Consulting Services for Digital Related Projects</h1>
        </div>
      </div>
      <div className="o-row">
        <div className="grid-row services-grid o-grid grid-columns-2">
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="1/6" onClick={() => openModal('modal1')}>
            <div className="c-icon small w-embed">
            <svg fill="currentColor" height={'64'} width={'64'} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.442 27.442" xmlSpace="preserve"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546 c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481 s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469 c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z M19.995,21.1H7.448V3.373h12.547V21.1z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
            </div>
            <h3 className="t-body-1">Mobile App Development</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="2/6" onClick={() => openModal('modal2')}>
          <div className="c-icon small w-embed">
          <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve" fill="currentColor"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <g> <path class="st0" d="M486.504,25.496H25.496C11.438,25.496,0,36.934,0,50.992v410.016c0,14.059,11.438,25.496,25.496,25.496 h461.008c14.058,0,25.496-11.437,25.496-25.496V50.992C512,36.934,500.562,25.496,486.504,25.496z M424.585,77.008 c15.806,0,28.618,12.813,28.618,28.618c0,15.806-12.812,28.618-28.618,28.618c-15.805,0-28.618-12.812-28.618-28.618 C395.968,89.821,408.78,77.008,424.585,77.008z M334.049,77.008c15.805,0,28.618,12.813,28.618,28.618 c0,15.806-12.813,28.618-28.618,28.618c-15.805,0-28.618-12.812-28.618-28.618C305.431,89.821,318.244,77.008,334.049,77.008z M243.512,77.008c15.806,0,28.618,12.813,28.618,28.618c0,15.806-12.812,28.618-28.618,28.618 c-15.805,0-28.618-12.812-28.618-28.618C214.894,89.821,227.707,77.008,243.512,77.008z M469.333,443.838H42.667V174.829h426.666 V443.838z"></path> <rect x="87.415" y="219.756" class="st0" width="226.341" height="45.61"></rect> <rect x="87.415" y="311.333" class="st0" width="337.17" height="45.61"></rect> </g> </g></svg>
            </div>
            <h3 className="t-body-1">Web Development</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="3/6" onClick={() => openModal('modal3')}>
          <div className="c-icon small w-embed">
          <svg width={'64'} height={'64'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13.1371 6.91018C13.4184 6.7695 13.7123 6.59981 14 6.40001V8C14 9.65685 11.3137 11 8 11C4.68629 11 2 9.65685 2 8V6.40001C2.28772 6.59981 2.58158 6.7695 2.86293 6.91018C4.28096 7.61919 6.09998 8 8 8C9.90002 8 11.719 7.61919 13.1371 6.91018Z" fill="currentColor"></path> <path d="M2 11.4V13C2 14.6569 4.68629 16 8 16C11.3137 16 14 14.6569 14 13V11.4C13.7123 11.5998 13.4184 11.7695 13.1371 11.9102C11.719 12.6192 9.90002 13 8 13C6.09998 13 4.28096 12.6192 2.86293 11.9102C2.58158 11.7695 2.28772 11.5998 2 11.4Z" fill="currentColor"></path> <path d="M8 0C11.3137 0 14 1.34315 14 3C14 4.65685 11.3137 6 8 6C4.68629 6 2 4.65685 2 3C2 1.34315 4.68629 0 8 0Z" fill="currentColor"></path> </g></svg>
            </div>
            <h3 className="t-body-1">Data Management Solutions</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="4/6" onClick={() => openModal('modal4')}>
            <div className="c-icon small w-embed">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g strokewidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <path d="M15.4695 11.2929C15.0789 10.9024 14.4458 10.9024 14.0553 11.2929C13.6647 11.6834 13.6647 12.3166 14.0553 12.7071C14.4458 13.0976 15.0789 13.0976 15.4695 12.7071C15.86 12.3166 15.86 11.6834 15.4695 11.2929Z" fill="currentColor"></path> <path d="M16.1766 9.17156C16.5671 8.78103 17.2003 8.78103 17.5908 9.17156C17.9813 9.56208 17.9813 10.1952 17.5908 10.5858C17.2003 10.9763 16.5671 10.9763 16.1766 10.5858C15.7861 10.1952 15.7861 9.56208 16.1766 9.17156Z" fill="currentColor"></path> <path d="M19.7121 11.2929C19.3216 10.9024 18.6885 10.9024 18.2979 11.2929C17.9074 11.6834 17.9074 12.3166 18.2979 12.7071C18.6885 13.0976 19.3216 13.0976 19.7121 12.7071C20.1027 12.3166 20.1027 11.6834 19.7121 11.2929Z" fill="currentColor"></path> <path d="M16.1766 13.4142C16.5671 13.0237 17.2003 13.0237 17.5908 13.4142C17.9813 13.8048 17.9813 14.4379 17.5908 14.8284C17.2003 15.219 16.5671 15.219 16.1766 14.8284C15.7861 14.4379 15.7861 13.8048 16.1766 13.4142Z" fill="currentColor"></path> <path d="M6 13H4V11H6V9H8V11H10V13H8V15H6V13Z" fill="currentColor"></path> <path fillRule="evenodd" clipRule="evenodd" d="M7 5C3.13401 5 0 8.13401 0 12C0 15.866 3.13401 19 7 19H17C20.866 19 24 15.866 24 12C24 8.13401 20.866 5 17 5H7ZM17 7H7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17H17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7Z" fill="currentColor"></path> </g></svg>
            </div>
            <h3 className="t-body-1">Games Development</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="5/6" onClick={() => openModal('modal5')}>
          <div className="c-icon small w-embed">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3ZM6.41421 7H9V4.41421L6.41421 7ZM7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13ZM7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H8C7.44772 18 7 17.5523 7 17Z" fill="currentColor"></path> </g></svg>
            </div>
            <h3 className="t-body-1">Document Conception and Design</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <a className="c-card c-block-item w-inline-block" role="group" aria-label="6/6" onClick={() => openModal('modal6')}>
          <div className="c-icon small w-embed">
          <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="drop" fill="currentColor" transform="translate(42.666667, 106.666667)"> <path d="M426.666667,4.26325641e-14 L426.666667,298.666667 L3.55271368e-14,298.666667 L3.55271368e-14,4.26325641e-14 L426.666667,4.26325641e-14 Z M85.3333333,234.666667 L42.666,234.666 L42.6666667,256 L85.333,256 L85.3333333,234.666667 Z M298.666,42.666 L128,42.666 L128,256 L298.666,256 L298.666,42.666 Z M384,234.666 L341.333333,234.666667 L341.333,256 L384,256 L384,234.666 Z M170.666667,96 L266.666667,149.333333 L170.666667,202.666667 L170.666667,96 Z M384,170.666 L341.333333,170.666667 L341.333333,192 L384,192 L384,170.666 Z M85.3333333,170.666667 L42.666,170.666 L42.666,192 L85.3333333,192 L85.3333333,170.666667 Z M384,106.666 L341.333333,106.666667 L341.333333,128 L384,128 L384,106.666 Z M85.3333333,106.666667 L42.666,106.666 L42.666,128 L85.3333333,128 L85.3333333,106.666667 Z M384,42.6666667 L341.333,42.666 L341.333333,64 L384,64 L384,42.6666667 Z M85.333,42.666 L42.6666667,42.6666667 L42.666,64 L85.3333333,64 L85.333,42.666 Z" id="Combined-Shape"> </path> </g> </g> </g></svg>
            </div>
            <h3 className="t-body-1">Video Editing</h3>
            <div className="c-icon block-arrow w-embed">
              <svg width={'20'} height={'20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="currentColor"></path>
              </svg>
            </div>
          </a>
          <div className="c-line tech-hoz hide-mobile" id="w-node-_49a7040a-af5d-67e0-3541-5cc642a706c8-c73c5655"></div>
          <div className="c-line tech-hoz-2 hide-mobile" id="w-node-_49a7040a-af5d-67e0-3541-5cc642a706c8-c73c5655"></div>
          <div className="c-line tech-ver-1" id="w-node-b3d8c949-042c-c076-7d24-21e042bea05b-c73c5655"></div>
        </div>
      </div>
    </div>
    <div className={`c-service-modal business-dts ${modalState.modal1 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Mobile App Development</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>We create user-friendly, high-performance mobile applications tailored to your business needs. Our apps are designed to provide seamless experiences on both iOS and Android platforms, helping you connect with your audience on the go.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal1 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal2 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Web Development</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>From sleek business websites to complex web applications, we develop responsive, secure, and visually appealing websites that deliver a superior user experience, ensuring your online presence is as dynamic as your brand.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal2 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal3 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Data Management Solutions </h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>Unlock the power of your data with our custom data management solutions. We organize, store, and analyze your data, helping you make informed decisions, improve efficiency, and drive growth.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal3 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal4 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Games Development</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>Bring your ideas to life with immersive and engaging games. Our team specializes in creating captivating game experiences, from concept to launch, ensuring your vision is translated into an unforgettable interactive journey.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal4 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal5 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Document Conception and Design</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>We transform complex information into visually compelling and professionally designed documents. Whether it’s reports, presentations, or marketing materials, we deliver clear, impactful designs that align with your brand.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal5 ? 'open' : ''}`}></div>
      <div className={`c-service-modal business-dts ${modalState.modal6 ? 'open' : ''}`}>
        <div className="c-service_top">
          <div className="c-service-title">
            <h2 className="t-display-4 is-serif">Video Editing</h2>
          </div>
        </div>
        <div className="c-service_bt">
          <div className="t-rich-text w-richtext">
            <p>Enhance your storytelling with our expert video editing services. From promotional videos to corporate presentations, we craft polished, visually captivating content that engages your audience and elevates your brand.</p>
          </div>
        </div>
        <div className="c-service-modal-close" onClick={closeModal}>
          <div className="t-micro-2">Close</div>
        </div>
      </div>
      <div className={`c-service-overlay ${modalState.modal6 ? 'open' : ''}`}></div>
    </>
  )
}

export default DigitalDetails;