import React, {useState, useEffect} from "react";
import './About.css';
import SectionObserver from './SectionObserver';
import EminesLogo from '../images/clients/emineslogo.png';
import Um6pLogo from '../images/clients/um6p_logo.png';

const About = () => {
  const [trigger, setTrigger] = useState(false);
  const [trigger2, setTrigger2] = useState(false);
  const [trigger3, setTrigger3] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the correct width on load
    return () => window.removeEventListener('resize', handleResize);
}, []);

  const handleIntersect = () => {
    setTrigger(true);
  };
  const handleIntersect2 = () => {
    setTrigger2(true);
  };
  const handleIntersect3 = () => {
    setTrigger3(true);
  };

  return (
    <>
      <div className="c-section hm-section-1 about" id="about">
        <div className="o-container">
          
          <div className="c-line"></div>
          <SectionObserver onIntersect={handleIntersect3}>
          <div className="o-row split-center reverse">
            <div className="c-split_lt">
              <div className="t-micro-1 ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;about</div>
              <h2 className={`t-display-4 is-serif ${trigger3? 'show' : ''}`}>
              {[
                  'Get',
                  'to',
                  'know',
                  'us'
                ].map((word, wordIndex, wordArray) => (
                  <div
                    className={`word ${word === 'your' ? 'highlight-word' : ''}`}
                    key={wordIndex}
                  >
                    {word.split('').map((char, charIndex) => (
                      <div
                        className={`char`}
                        key={charIndex}
                        style={{ '--char-index': wordIndex * 10 + charIndex }}
                      >
                        {char}
                      </div>
                    ))}
                    &nbsp;
                  </div>
                ))}
              </h2>
              <SectionObserver onIntersect={handleIntersect}>
              <div className={`o-col _w-17 sm-w-24 ${trigger ? 'fade-in' : ''}`}>
                <p className="t-body-4">EJE is the junior-entreprise of Emines, School Of Industrial Management. It is a legal non-profit company led 100% by Emines students. EJE presents various services in various fields, from <span className="t-body-4">business</span>, <span className="t-body-4">digital</span> to <span className="t-body-4">analytics & AI</span>.</p>
              </div>
              </SectionObserver>
              <div className={`c-btn-wrap ${trigger ? 'fade-in' : ''}`}>
                <a className="c-btn w-inline-block" href="/services">
                  <div className="c-btn-inner">
                    <p className="t-body-4 is-medium">Discover our services</p>
                    <div className="c-icon btn-arrow w-embed">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0.5C7.04875 1.33538 4.51216 1.67936 0.878024 1.28624L0.756027 2.68673C3.31699 2.957 5.19504 2.93243 7.0975 2.4656L-0.000291876 9.5172L0.975286 10.5L8.04869 3.39926C7.58521 5.3403 7.56076 7.20762 7.82897 9.81204L9.21922 9.68919C8.82909 6.02826 9.19503 3.4484 10 0.5Z" ></path>
                          </svg>
                      </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="c-line vertical-divider hide-tablet"></div>
            <div className="c-split_rt space reverse">
              <div className="c-img-contain">
                  <div class="o-container flex-direction-row">
                      <div className={`bb-container ${trigger2 ? 'fade-in' : ''}`}>
                        <a href="https://www.emines-ingenieur.org/">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
      <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="#fff"></path>
    </svg>
                        <div className="pattern"></div>
                        <div className="c-content">
                        <div className="c-logo-container">
                          <div className="logo-inner">
                            <img src={EminesLogo} className="logo-emines"/>
                          </div>
                          <div className="logo-desc">
                            <div className="big-number">+10</div>
                            <p className="about-small-text">Years of experience</p>
                          </div>
                        </div>
                        <div className="description hide-tablet">
                          <p className="desc-text">Emines-School Of Industrial Management combines the technical side of engineers with managerial qualities required today in business.</p>
                        </div>
                        </div>
                        </a>
                      </div>
                      <div className={`bb-container ${trigger2 ? 'fade-in' : ''}`}>
                        <a href="https://um6p.ma/">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
      <path d="M20 0C14.0976 1.67076 9.02439 2.35872 1.7561 1.57248L1.5122 4.37347C6.63415 4.91401 10.3902 4.86487 14.1951 3.9312L0 18.0344L1.95122 20L16.0976 5.79853C15.1707 9.68059 15.122 13.4152 15.6585 18.6241L18.439 18.3784C17.6585 11.0565 18.3902 5.89681 20 0Z" fill="#fff"></path>
    </svg>
                        <div className="c-logo-container">
                          <div className="logo-inner">
                            <img src={Um6pLogo} className="logo-um6p"/>
                          </div>
                          <div className="logo-desc">
                            <div className="big-number">+30</div>
                            <p className="about-small-text">Programs of masters and research</p>
                          </div>
                        </div>
                        <div className="description hide-tablet">
                          <p className="desc-text">Located in the neo-green city of Ben Guerir, UM6P takes the responsbility to push development in Africa.</p>
                        </div>
                        </a>
                      </div>

                  </div>
              </div>
            </div>
          </div>
          </SectionObserver>
          <div className="c-line"></div>
        </div>
      </div>
    </>
  )
}

export default About;