import React from "react";
import HeroBanner from './HeroBanner';
import backgroundImage from '../images/business-bg-02.jpg';
import logoImage from '../images/eje-business-logo.png';

const BusinessHero = () => {
  return (
    <>
    <HeroBanner
      backgroundImage={backgroundImage}
      logoImage={logoImage}
      title="Business"
    />
    </>
  )
}

export default BusinessHero;